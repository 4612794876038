import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, Button, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { useTable, useSortBy } from "react-table";

import { CheckCircle, XCircle, Clock} from "react-feather";

import NotyfContext from "../contexts/NotyfContext";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import authenticatedFetch from "../utils/fetch"
import DocumentList from "../utils/DocumentList"
import EditableDetail from "../utils/EditableDetail"
import UsStateSelect from "../utils/UsStateSelect"
import EditableAddress from "../utils/EditableAddress";
import ReadOnlyAddress from "../utils/ReadOnlyAddress";
import HubParticipationStatusTable from "../utils/HubParticipationStatusTable";
import getActivityDateTimeDisplay from "../utils/datetime"
import OpenTasks from "../utils/OpenTasks";

import doctor1 from "../assets/img/avatars/logo_unknown_doctor.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";

const Provider = ({ photoUrl, practiceId, id, lastName, firstName, title, npi, isFacilityList=false }) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);

  useEffect(() => {
    authenticatedFetch({
      path: "provider/participationstatus/" + (isFacilityList ? "facility/" : "") + id + "/" + practiceId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [id, practiceId, isFacilityList])

  var carrierList;

  if (error)
  {
    carrierList = <td colSpan="6">Something went wrong... please reload the page...</td>;
  }
  else if (!isLoaded)
  {
    carrierList = <td colSpan="6">Loading...</td>;
  }
  else
  {
    var res = isFacilityList ? data.hubs : data.carriers;
    
    carrierList = res && res.length > 0
      && res.map((carrier, i) => {
      return (
        <td key={carrier.id}>{carrier.isNotApplicable ? <>(n/a)</> : (carrier.isPending ? <Clock className="text-warning" /> : (carrier.isActive ? <CheckCircle className="text-success" /> : <XCircle className="text-danger" />))}</td>
      )
    }, this);
  }

  return (
    <>
        <td>
          <img
              src={photoUrl == null || photoUrl === '' ? doctor1 : photoUrl}
              width="48"
              height="48"
              className="rounded-circle me-2"
              alt="Avatar"
          />
        </td>
        <td>
          {(isH3Admin || authorities.includes("PROVIDER_BASIC_EDIT") || authorities.includes("PROVIDER_BASIC_VIEW")) ?
            <a href={"/provider/" + id}>
            {lastName + ","}&nbsp;{firstName}
            </a>
          : <>{lastName + ","}&nbsp;{firstName}</>
          }
        </td>
        <td>{title}</td>
        {carrierList}
        {carrierList && carrierList.length > 8 ?
        <td>
          {(isH3Admin || authorities.includes("PROVIDER_BASIC_EDIT") || authorities.includes("PROVIDER_BASIC_VIEW")) ?
            <a href={"/provider/" + id}>
            {lastName + ","}&nbsp;{firstName}
            </a>
          : <>{lastName + ","}&nbsp;{firstName}</>
          }
        </td>
        : <></>
        }
    </>
  );
}

function stripCredential(credential) {
  if (credential == null)
  {
    return '';
  }

  return credential.toLowerCase().replace(/[^a-z]/g, '').trim();
}

const getCredentialRankValueString = (credential) => {
  if (credential != null && credential !== '')
  {
    credential = stripCredential(credential);
    if ((credential.indexOf("md") > -1 || credential.indexOf("do") > -1 || credential.indexOf("dpm") > -1) && credential.indexOf("mdt") <= 0)
    {
      return "5"
    }
    if (credential.indexOf("pa") > -1 || credential.indexOf("ma") > -1 || credential.indexOf("np") > -1)
    {
      return "4";
    }
    if (credential.indexOf("pt") > -1)
    {
      return "3";
    }
    
    return "2";
  }

  return "1";
};

const providerNameSort = (rowA, rowB) => {
  if (rowA.original.lastName === rowB.original.lastName) {

    if (rowA.original.firstName === rowB.original.firstName) {
      return rowA.original.middleName.localeCompare(rowB.original.middleName);
    }
    
    return rowA.original.firstName.localeCompare(rowB.original.firstName);
  }

  return rowA.original.lastName.localeCompare(rowB.original.lastName);
};

const ProviderSortableList = ({practiceId, isFacilityList=false}) => {
  const [error, setError] = useState(null);
  const [isHeaderDataLoaded, setIsHeaderDataLoaded] = useState(false);
  const [isProviderDataLoaded, setIsProviderDataLoaded] = useState(false);
  const [headerData, setHeaderData] = useState([]);
  const [providerData, setProviderData] = useState([]);

  const [isExporting, setIsExporting] = useState(false);

  const notyf = useContext(NotyfContext);

  useEffect(() => {
    authenticatedFetch({
      path: "provider/practice/" + practiceId,
      successHandler: function(result) {
        setIsProviderDataLoaded(true);
        setProviderData(result);
      },
      errorHandler: function(error) {
        setIsProviderDataLoaded(true);
        setError(error);
      }
    });

    authenticatedFetch({
      path: "practice/participationstatus/" + (isFacilityList ? "facility/" : "") + practiceId,
      successHandler: function(result) {
        setIsHeaderDataLoaded(true);
        setHeaderData(result);
      },
      errorHandler: function(error) {
        setIsHeaderDataLoaded(true);
        setError(error);
      }
    });
  }, [practiceId, isFacilityList])

  const columns = React.useMemo(
    () => {
      var headers = [
        {
          key: "id",
          accessor: "id",
        },
        {
          key: "photoUrl",
          accessor: "photoUrl",
        },
        {
          key: "lastName",
          accessor: "lastName",
        },
        {
          key: "firstName",
          accessor: "firstName",
        },
        {
          key: "middleName",
          accessor: "middleName",
        },
        {
          Header: "Provider",
          key: "provider",
          id: "provider",
          accessor: () => {return null},  // Have to have something here or the sort props don't work
          sortType: (rowA, rowB, columnId, desc) => {
            return providerNameSort(rowA, rowB);
          },
        },
        {
          Header: 'Creds.',
          accessor: "credentials",
          key: "credentials",
          sortType: (rowA, rowB, columnId, desc) => {
            var rankA = getCredentialRankValueString(rowA.values[columnId]);
            var rankB = getCredentialRankValueString(rowB.values[columnId]);

            if (rankA === rankB)
            {
              if (stripCredential(rowA.values[columnId]) === stripCredential(rowB.values[columnId]))
              {
                // We want credentials "DESC" (so MD first), but then name *ASC*
                return -1 * providerNameSort(rowA, rowB);
              }

              return rowA.values[columnId].localeCompare(rowB.values[columnId]);
            }
            
            return rankA.localeCompare(rankB);
          },
        }
      ];

      var headerDataToUse = [];
      if (isFacilityList)
      {
        headerDataToUse = headerData.hubs;
      }
      else
      {
        headerDataToUse = headerData.carriers;
      }

      if (isHeaderDataLoaded && headerDataToUse && headerDataToUse.length > 0)
      {
        headers = headers.concat(headerDataToUse.map((carrier, i) => {
          return (
            {
              Header: carrier.name,
              id: carrier.id,
            }
          )
        }, this));

        if (headerDataToUse.length > 8)
        {
          headers.push(
            {
              Header: "Provider",
              key: "provider2",
              id: "provider2",
            },
          );
        }
      }

      return headers;
    },
  [headerData, isHeaderDataLoaded, isFacilityList]
  );
  

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: {sortBy} } =
  useTable(
    {
      columns,
      data: providerData,
      autoResetSortBy: false,
      initialState: {
        hiddenColumns: ['id', 'lastName', 'firstName', 'middleName'],
        sortBy: [
          {
              id: 'provider',
              desc: false
          },
        ]
      }
    },
    useSortBy,
  );

  const handleExportClicked = (e) => {
    e.preventDefault();

    var sort = '';
    if (sortBy.length > 0)
    {
      sort = sortBy[0].id + (sortBy[0].desc ? 'Desc' : 'Asc');
    }

    setIsExporting(true);

    authenticatedFetch({
      method: "GET",
      path:  "report/enrollmentmatrix/" + (isFacilityList ? "facility/" : "carrier/")  + "practice/" + practiceId + "?sort=" + sort,
      successHandler: function(result) {
        window.open(result.url);
        setIsExporting(false);
      },
      errorHandler: function(error) {
        setIsExporting(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
          <Card.Title>
              {isFacilityList ? "Location" : "Provider Enrollment"} Status
              { isExporting ? 
                <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '12px', marginTop: '4px'}}/>
                :
                <Form.Label onClick={(e) => {handleExportClicked(e)}} style={{cursor: "pointer", display: "inline", fontSize: "0.8em", fontWeight: "normal", color: '#2B7A78', marginTop: "7px", marginRight: "3px"}} className="float-end">
                  <FontAwesomeIcon icon={faFilePdf} size="lg" style={{color: '#2B7A78'}}/>
                  &nbsp;
                  Export
                </Form.Label>
              }
          </Card.Title>
        </Card.Header>
      <Card.Body style={{overflow: 'scroll', maxHeight: '700px', paddingTop: 0}}>
        <Table striped hover {...getTableProps()}>
          <thead style={{position: 'sticky', top: 0, background: '#FFFFFF'}}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    const { key, style, ...restHeaderProps } = (column.key !== "provider" && column.key !== "credentials") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th {...restHeaderProps} key={key} style={{...style, whiteSpace: (column.key === "provider" || column.key === "credentials") ? "nowrap" : ""}}>
                        {column.render("Header")}
                        {(column.key === "provider" || column.key === "credentials") ? (
                          <>
                          {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="ms-2" />
                          )}
                          </>
                        ) : <></>
                        }
                      </th>
                    )
                  }
                  )}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {
              error ? (<tr><td colSpan={4}>Something went wrong; please reload the page...</td></tr>) : ( 
                !isProviderDataLoaded ? (<tr><td colSpan={8}>Loading...</td></tr>) : (
                rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();
                  return (
                    <tr {...restRowProps} key={key}>
                      <Provider key={row.values.id} id={row.values.id} practiceId={practiceId} photoUrl={row.values.photoUrl} lastName={row.values.lastName} firstName={row.values.firstName} title={row.values.credentials} npi={row.values.npi} isFacilityList={isFacilityList}/>
                    </tr>
                  );
                })))
              }
            </tbody>
          </Table>
      </Card.Body>
    </Card>
  )
};


const EditablMailingAddress = ({address1, address2, city, state, zip, onValueChanged}) => {
  return (
    <>
    <EditableDetail placeholder="Maililng address 1" originalValue={address1} name="mailingAddress1" onValueChanged={onValueChanged}/>
    <EditableDetail placeholder="Maililng address 2" originalValue={address2} name="mailingAddress2" onValueChanged={onValueChanged}/>
    <EditableDetail placeholder="Maililng city" originalValue={city} name="mailingCity" onValueChanged={onValueChanged}/>
    <UsStateSelect placeholder="Maililng state" originalValue={state} name="mailingState" onValueChanged={onValueChanged}/>
    <EditableDetail placeholder="Maililng zip" originalValue={zip} name="mailingZip" mask="99999-9999" onValueChanged={onValueChanged}/>
    </>
  );
}

const EditablRemitAddress = ({address1, address2, city, state, zip, onValueChanged}) => {
  return (
    <>
    <EditableDetail placeholder="Remit address 1" originalValue={address1} name="remitAddress1" onValueChanged={onValueChanged}/>
    <EditableDetail placeholder="Remit address 2" originalValue={address2} name="remitAddress2" onValueChanged={onValueChanged}/>
    <EditableDetail placeholder="Remit city" originalValue={city} name="remitCity" onValueChanged={onValueChanged}/>
    <UsStateSelect placeholder="Remit state" originalValue={state} name="remitState" onValueChanged={onValueChanged}/>
    <EditableDetail placeholder="Remit zip" originalValue={zip} name="remitZip" mask="99999-9999" onValueChanged={onValueChanged}/>
    </>
  );
}

const Practice = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingDetails, setEditingDetails] = useState(false); 
  const [localData, setLocalData] = useState([])
  const [isExpanded, setIsExpanded] = useState(true);

  let { id } = useParams();

  const notyf = useContext(NotyfContext);

  const { isH3Admin, authorities, navbarRefresh, setNavbarRefresh } = useContext(UserSettingsContext);

  useEffect(() => {
    setEditing(false);

    authenticatedFetch({
      path: "practice/" + id,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
        // Otherwise seem to point to the same obj, and updating one updates the other?
        var copy={};
        Object.assign(copy, result);
        setLocalData(copy);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [id])

  const handleExpandClicked = (e) => {
    e.preventDefault();
    setIsExpanded(true);
  }

  const handleCollapseClicked = (e) => {
    e.preventDefault();
    setIsExpanded(false);
  }

  const handleEditClicked = () => {
    // Treat it like a cancel of the other one if it was open, for simplicity
    setEditingDetails(false);  
    var copy={};
    Object.assign(copy, data);
    setLocalData(copy);

    setEditing(true);
  }

  const handleEditDetailsClicked = () => {
    setEditing(false);
    var copy={};
    Object.assign(copy, data);
    setLocalData(copy);

    setEditingDetails(true);
  }

  const handleSaveClicked = (editDetails) => {
    setEditing(false);
    setEditingDetails(false);

    authenticatedFetch({
      path: "practice/" + id,
      method: "POST",
      requestData: localData,
      successHandler: function(result) {
        setData(result);
        var copy={};
        Object.assign(copy, result);
        setLocalData(copy);
        notyf.open({type: "success", message: "Practice information updated!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        setNavbarRefresh(navbarRefresh + 1);
      },
      errorHandler: function(error) {
        if (editDetails)
        {
          setEditingDetails(false);
        }
        else
        {
          setEditing(true);
        }
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const handleCancelClicked = () => {
    setEditing(false);
    setEditingDetails(false);

    var copy={};
    Object.assign(copy, data);
    setLocalData(copy);
  }

  const onValueChanged = (name, newValue) => {
    if (newValue != null && name != null && (name.toLowerCase().indexOf("zip") > -1 || name.indexOf("phone") > -1 || name.indexOf("fax") > -1 || name.indexOf("npi") > -1 || name.indexOf("tin") > -1 || name.indexOf("officeManagerPhone") > -1 ))
    {
      // Remove any remaining mask characters from the truly numeric one; can't do anything about the phone parens and dash on partially completed phones
      newValue = newValue.replace(/_/g, '');

      if (name.toLowerCase().indexOf("zip") > -1 && newValue.length <= 6)
      {
        // Chop the trailling dash
        newValue = newValue.substring(0, newValue.length - 1); // Comes through as 980- for example
      }
    }

    localData[name] = newValue
    setLocalData(localData)
  }

  var endDateDisplay = null;
  if (data.endDate != null)
  {
    var parts = data.endDate.split("-");
    const endDateObj = new Date(parts[0], parts[1] - 1, parts[2]);
    endDateDisplay = new Date(endDateObj).toLocaleDateString("en-US");
  }

  if (!isLoaded) {
    return <div>Loading...</div>
  }
  else if (error || !data)
  {
    return <div>Something went wrong... please reload the page...</div>
  }
  else
  {
    var notesReadOnly = '';
    if (localData.notes && localData.notes.length > 0) {
      notesReadOnly = localData.notes.split("\n").map(function(item, idx) {
        return ( 
            <span key={idx}>
                {item}
                <br />
            </span>
         )
      });
    }

    let showLeftCol = (isH3Admin || authorities.includes("PRACTICE_SENSITIVE_DETAILS_VIEW") || authorities.includes("PRACTICE_SENSITIVE_DETAILS_EDIT")
      || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT"));

    return (
        <React.Fragment>
            <Helmet title={data.name} />
            <Container fluid className="p-0" style={{marginTop: "-20px"}}>
            <Row style={{background: '#DEF2F1', padding: '20px', marginBottom: '20px'}}>
                <Col lg="5">
                  {
                  editing ?
                  <EditableDetail width="81%" originalValue={localData.name} name="name" onValueChanged={onValueChanged}/>
                  :
                  <h1 className="h3 mb-3">{data.name}</h1>
                  }
                  <Table className="table-sm" style={{marginLeft:'-10px', fontSize: "0.95em", lineHeight: "1.2"}}>
                    <tbody>
                    <tr>
                      <td><b>Phone:</b></td>
                      <td>
                      {editing?
                      <EditableDetail originalValue={localData.phone1} mask='(999) 999-9999' name="phone1" onValueChanged={onValueChanged}/>
                      :
                      localData.phone1
                      }
                      </td>
                    </tr>
                    <tr>
                      <td><b>Fax:</b></td>
                      <td>
                      {editing?
                      <EditableDetail originalValue={localData.fax} mask='(999) 999-9999' name="fax" onValueChanged={onValueChanged}/>
                      :
                      localData.fax
                      }
                      </td>
                    </tr>
                    <tr>
                      <td><b>Email:</b></td>
                      <td>
                      {editing?
                      <EditableDetail originalValue={localData.email1} name="email1" onValueChanged={onValueChanged}/>
                      :
                      localData.email1
                      }
                      </td>
                    </tr>
                    <tr>
                      <td><b>TIN:</b></td>
                      <td>
                      {editing?
                      <EditableDetail originalValue={localData.tin} name="tin" mask="999999999" onValueChanged={onValueChanged}/>
                      :
                      localData.tin
                      }
                      </td>
                    </tr>
                    <tr>
                      <td><b>Office Manager:</b></td>
                      <td>
                      {editing?
                      <EditableDetail originalValue={localData.officeManager} name="officeManager" onValueChanged={onValueChanged}/>
                      :
                      localData.officeManager
                      }
                      </td>
                    </tr>
                    <tr>
                      <td><b>Office Manager Phone:</b></td>
                      <td>
                      {editing?
                      <EditableDetail originalValue={localData.officeManagerPhone} mask='(999) 999-9999' name="officeManagerPhone" onValueChanged={onValueChanged}/>
                      :
                      localData.officeManagerPhone
                      }
                      </td>
                    </tr>
                    <tr>
                      <td><b>Office Manager Email:</b></td>
                      <td>
                      {editing?
                      <EditableDetail originalValue={localData.officeManagerEmail} name="officeManagerEmail" onValueChanged={onValueChanged}/>
                      :
                      localData.officeManagerEmail
                      }
                      </td>
                    </tr>
                    {endDateDisplay &&
                    <tr>
                      <td colSpan={2} style={{borderBottomWidth: "1px", fontWeight: "bold", fontSize: "1.1em"}} className="text-warning">End Date: {endDateDisplay}</td>
                    </tr>
                    }
                    </tbody>
                  </Table>
                </Col>
                <Col lg="5">
                  <Card>
                    <Card.Header>
                      <Card.Title>Notes</Card.Title>
                    </Card.Header>
                    <Card.Body style={{marginTop: '-30px'}}>{editing ? <EditableDetail originalValue={localData.notes} name="notes" inputType="textarea" onValueChanged={onValueChanged}/> : notesReadOnly}</Card.Body>
                  </Card>
                </Col>
                <Col lg="1"></Col>
                
                {(isH3Admin || authorities.includes("PRACTICE_BASIC_EDIT")) ?
                <Col lg="1" style={{marginLeft: '0px'}} className="text-end">
                     {editing ?
                     <>
                      <Button size="sm" variant="primary" className="float-end" onClick={() => {handleSaveClicked(false)}}>Save</Button>
                      <br />
                      <Button size="sm" variant="secondary" style={{marginTop: '10px'}} className="float-end" onClick={() => {handleCancelClicked()}}>Cancel</Button>
                    </>
                     :
                     <Button size="sm" variant="primary" className="float-end" onClick={() => {handleEditClicked()}}>Edit</Button>
                    }
                </Col>
                : <></>
                }
                <Row style={{justifyContent:"end", fontSize: "0.8em", fontStyle:"italic"}}>
                  <Col lg="auto">Updated: {getActivityDateTimeDisplay(data.updated)}{data.updatedBy != null ? " by " + data.updatedBy : ""}</Col>
                </Row>
            </Row>
            <Row>
                {showLeftCol ?
                <Col xl="3">
                  {(isH3Admin || authorities.includes("PRACTICE_SENSITIVE_DETAILS_VIEW") || authorities.includes("PRACTICE_SENSITIVE_DETAILS_EDIT")) ?
                  <Card>
                  <Card.Header style={{marginBottom: '-25px'}}>
                    <Card.Title style={{marginBottom: '-20px'}}>Practice Details
                      {(isH3Admin || authorities.includes("PRACTICE_SENSITIVE_DETAILS_EDIT")) ?
                      <>
                      {editingDetails ?
                      <span className="float-end">
                        <Button variant="primary" className="float-end" size="sm" onClick={() => {handleSaveClicked(true)}}>Save</Button>
                        <br />
                        <Button variant="secondary" className="float-end" size="sm" style={{marginTop: '10px'}} onClick={() => {handleCancelClicked()}}>Cancel</Button>
                      </span>
                      :
                      <Button style={{display: !isExpanded ? "none" : ""}} size="sm" variant="primary" className="float-end" onClick={() => {handleEditDetailsClicked()}}>Edit</Button>
                      }
                      </> : <></>
                      }
                      <br />
                      { isExpanded ?
                      <small><a href="#" onClick={(e) => {handleCollapseClicked(e)}}>-collapse</a></small>
                      :
                      <small><a href="#" onClick={(e) => {handleExpandClicked(e)}}>+expand</a></small>
                      }
                    <div style={{clear: "both"}}>&nbsp;</div>
                    </Card.Title>
                    <hr />
                  </Card.Header>
                  <Collapse in={isExpanded}>
                    <Table className="table-borderless table-sm">
                      <tbody>
                        <tr>
                          <td><b>NPI:</b></td>
                          {editingDetails?
                          <td><EditableDetail originalValue={localData.npi} name="npi" mask="9999999999" onValueChanged={onValueChanged}/></td>
                          :
                          <td style={{fontFamily: "monospace"}}>{localData.npi}</td>
                          }
                        </tr>
                        <tr>
                          <td><b>Address:</b></td>
                          <td>
                          {editingDetails ?
                          <EditableAddress address1={localData.address1} address2={localData.address2} city={localData.city} state={localData.state} zip={localData.zip} onValueChanged={onValueChanged} />
                          : 
                          <ReadOnlyAddress address1={localData.address1} address2={localData.address2} city={localData.city} state={localData.state} zip={localData.zip} />
                          }
                          </td>
                        </tr>
                        <tr>
                          <td><b>County:</b></td>
                          <td>
                          {editingDetails?
                          <EditableDetail originalValue={localData.county} name="county" onValueChanged={onValueChanged}/>
                          :
                          localData.county
                          }
                          </td>
                        </tr>
                        <tr>
                          <td><b>Mailing Address:</b></td>
                          <td>
                          {editingDetails ?
                          <EditablMailingAddress address1={localData.mailingAddress1} address2={localData.mailingAddress2} city={localData.mailingCity} state={localData.mailingState} zip={localData.mailingZip} onValueChanged={onValueChanged} />
                          : 
                          <ReadOnlyAddress address1={localData.mailingAddress1} address2={localData.mailingAddress2} city={localData.mailingCity} state={localData.mailingState} zip={localData.mailingZip} />
                          }
                          </td>
                        </tr>
                        <tr>
                          <td><b>Remit Address:</b></td>
                          <td>
                          {editingDetails ?
                          <EditablRemitAddress address1={localData.remitAddress1} address2={localData.remitAddress2} city={localData.remitCity} state={localData.remitState} zip={localData.remitZip} onValueChanged={onValueChanged} />
                          : 
                          <ReadOnlyAddress address1={localData.remitAddress1} address2={localData.remitAddress2} city={localData.remitCity} state={localData.remitState} zip={localData.remitZip} />
                          }
                          </td>
                        </tr>
                        <tr>
                          <td><b>Office Hours:</b></td>
                          <td>
                          {editingDetails?
                          <EditableDetail originalValue={localData.officeHours} name="officeHours" onValueChanged={onValueChanged}/>
                          :
                          localData.officeHours
                          }
                          </td>
                        </tr>
                        <tr>
                          <td><b>Group Medicare:</b></td>
                          <td>
                          {editingDetails?
                          <EditableDetail originalValue={localData.groupMedicare} name="groupMedicare" onValueChanged={onValueChanged}/>
                          :
                          localData.groupMedicare
                          }
                          </td>
                        </tr>
                        <tr>
                          <td><b>Group Medicaid:</b></td>
                          <td>
                          {editingDetails?
                          <EditableDetail originalValue={localData.groupMedicaid} name="groupMedicaid" onValueChanged={onValueChanged}/>
                          :
                          localData.groupMedicaid
                          }
                          </td>
                        </tr>
                        <tr>
                          <td><b>Other Group IDs:</b></td>
                          <td>
                          {editingDetails?
                          <EditableDetail originalValue={localData.otherGroupIds} name="otherGroupIds" onValueChanged={onValueChanged}/>
                          :
                          localData.otherGroupIds
                          }
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Collapse>
                  </Card>
                  : <></>
                  }
                  {(isH3Admin || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT")) ?
                    <Card>
                      <Card.Header>
                        <Card.Title>Open Tasks</Card.Title>
                      </Card.Header>
                      <OpenTasks entityType="practice" entityId={id} />
                    </Card>
                  : <></>
                  }
                </Col>
                : <></>
                }
                <Col xl={showLeftCol ? "9" : "12"}>
                  <ProviderSortableList practiceId={id} />

                  {(isH3Admin || authorities.includes("PROVIDER_FACILITY_VIEW") || authorities.includes("PROVIDER_FACILITY_EDIT")) ?
                  <ProviderSortableList practiceId={id} isFacilityList={true} />
                  : <></>
                  }
  
                  {(isH3Admin || authorities.includes("PRACTICE_DATA_HUB_VIEW") || authorities.includes("PRACTICE_DATA_HUB_EDIT")) ?
                  <HubParticipationStatusTable entityType="practice" entityId={id} entityName={data.name} canEdit={isH3Admin || authorities.includes("PRACTICE_DATA_HUB_EDIT")} />
                  : <></>
                  }

                  {(isH3Admin || authorities.includes("PRACTICE_DOCUMENT_VIEW") || authorities.includes("PRACTICE_DOCUMENT_EDIT")) ?
                  <Card>
                    <Card.Header style={{marginBottom: '-25px'}}>
                      <Card.Title style={{marginBottom: '-15px'}}>Documents</Card.Title>
                      <br />
                    </Card.Header>
                    <Card.Body>
                      <DocumentList entityId={id} entityType="practice" canEdit={isH3Admin || authorities.includes("PRACTICE_DOCUMENT_EDIT")} />
                    </Card.Body>
                  </Card>
                  : <></>
                  }
                </Col>
            </Row>
            </Container>
        </React.Fragment>
    );
  }
};

export default Practice;
