import React, { useState, useEffect, useContext } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import logo from "../../assets/img/logo.png";
import RevenueTeamLabel from "../RevenueTeamLabel";

import { UserSettingsContext } from "../../contexts/UserSettingsContext";

import authenticatedFetch from "../../utils/fetch"

const Sidebar = ({ items, showFooter = false }) => {
  const { isOpen, setBlockedBadge, blockedBadge, setFppeBlockedBadge, fppeBlockedBadge } = useSidebar();
  const { isH3Admin, authorities, navbarRefresh } = useContext(UserSettingsContext);

  // TODO - just move the item array here, but for now, repopulate the key from this ajax call

  const [data, setData] = useState([]);

  useEffect(() => {
    if (isH3Admin || authorities.includes("PRACTICE_BASIC_VIEW") || authorities.includes("PRACTICE_BASIC_EDIT"))
    {
      authenticatedFetch({
        path: "practice/revenueteam",
        successHandler: function(result) {
          setData(result);
        }
      });
    }
  }, [isH3Admin, authorities, navbarRefresh]);

  let filteredItems = [];

  // // Has any referral access
  // if (isH3Admin || authorities.includes("INBOUND_FAX_VIEW") || authorities.includes("INBOUND_FAX_EDIT")
  //   || authorities.includes("REFERRAL_SCHEDULING_VIEW") || authorities.includes("REFERRAL_SCHEDULING_EDIT"))
  // {
  //   let filteredReferralPages = [];

  //   if (isH3Admin || authorities.includes("INBOUND_FAX_VIEW") || authorities.includes("INBOUND_FAX_EDIT"))
  //   {
  //     authenticatedFetch({
  //       path: "inbound_fax/new",
  //       successHandler: function(result) {
  //         setNewFaxBadge(result)
  //       }
  //     });

  //     if (newFaxBadge == null || newFaxBadge <= 0)
  //     {
  //       items[0].pages[0].badge = null;
  //     }
  //     else
  //     {
  //       items[0].pages[0].badge = newFaxBadge;
  //     }

  //     // Inbound faxes
  //     filteredReferralPages.push(items[0].pages[0]);
  //   }

  //   if (isH3Admin || authorities.includes("REFERRAL_SCHEDULING_VIEW") || authorities.includes("REFERRAL_SCHEDULING_EDIT"))
  //   {
  //     authenticatedFetch({
  //       path: "referral/pendingoutreach",
  //       successHandler: function(result) {
  //         setPendingOutreachBadge(result)
  //       }
  //     });

  //     if (pendingOutreachBadge == null || pendingOutreachBadge <= 0)
  //     {
  //       items[0].pages[1].badge = null;
  //     }
  //     else
  //     {
  //       items[0].pages[1].badge = pendingOutreachBadge;
  //     }

  //     // Referral scheduling
  //     filteredReferralPages.push(items[0].pages[1]);
  //   }

  //   var copy = {};
  //   Object.assign(copy, items[0]);
  //   copy.pages = filteredReferralPages;

  //   filteredItems.push(copy);
  // }

  // Has any enrollment access
  if (isH3Admin || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT")
  || authorities.includes("PRACTICE_BASIC_VIEW") || authorities.includes("PRACTICE_BASIC_EDIT")
  || authorities.includes("PROVIDER_BASIC_VIEW") || authorities.includes("PROVIDER_BASIC_EDIT"))
  {
    let filteredEnrollmentPages = [];

    // They get (some portion at least) of the enrollment dashboard
    filteredEnrollmentPages.push(items[1].pages[0]);

    // Tasks
    if (isH3Admin || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT"))
    {
        // Do it on load (and then on tasks page any time we update a task... doesn't work if someone else who is logged in does it...
      authenticatedFetch({
        path: "task/blocked",
        successHandler: function(result) {
          setBlockedBadge(result)
        }
      });

      if (blockedBadge == null || blockedBadge <= 0)
      {
        items[1].pages[1].badge = null;
      }
      else
      {
        items[1].pages[1].badge = blockedBadge;
      }

      filteredEnrollmentPages.push(items[1].pages[1]);
    }

    if (isH3Admin || authorities.includes("PRACTICE_BASIC_VIEW") || authorities.includes("PRACTICE_BASIC_EDIT"))
    {
      const practiceList = data && data.length > 0
      && data.map((practice, i) => {
        return (
          {
            href: "/practice/" + practice.id,
            title: practice.name
          }
        )
      }, this);
      items[1].pages[2].children = practiceList;

      filteredEnrollmentPages.push(items[1].pages[2]);
    }

    var copy = {};
    Object.assign(copy, items[1]);
    copy.pages = filteredEnrollmentPages;

    filteredItems.push(copy);
  }

  // Has any FPPE access
  if (isH3Admin || authorities.includes("FPPE_TASK_VIEW") || authorities.includes("FPPE_TASK_EDIT"))
  {
    let filteredFppePages = [];

    if (isH3Admin || authorities.includes("FPPE_TASK_VIEW") || authorities.includes("FPPE_TASK_EDIT"))
    {
      authenticatedFetch({
        path: "fppetask/blocked",
        successHandler: function(result) {
          setFppeBlockedBadge(result)
        }
      });

      if (fppeBlockedBadge == null || fppeBlockedBadge <= 0)
      {
        items[2].pages[0].badge = null;
      }
      else
      {
        items[2].pages[0].badge = fppeBlockedBadge;
      }

      // FPPE Tasks
      filteredFppePages.push(items[2].pages[0]);
    }

    var copy = {};
    Object.assign(copy, items[2]);
    copy.pages = filteredFppePages;

    filteredItems.push(copy);
  }

  if (authorities.includes("MESSAGING_VIEW") || authorities.includes("MESSAGING_EDIT") || isH3Admin)
  {
    // Both share the same permissions, so just add them
    filteredItems.push(items[3]);
  }

  if (isH3Admin || authorities.includes("PROVIDER_SENSITIVE_DETAILS_VIEW") || authorities.includes("PROVIDER_SENSITIVE_DETAILS_EDIT")
    || authorities.includes("PROVIDER_DOCUMENT_VIEW") || authorities.includes("PROVIDER_DOCUMENT_EDIT")
    || authorities.includes("PROVIDER_FACILITY_VIEW") || authorities.includes("PROVIDER_FACILITY_EDIT")
    || authorities.includes("PROVIDER_CME_VIEW") || authorities.includes("PROVIDER_CME_EDIT")
    || authorities.includes("PROVIDER_BOARD_CERTIFICATION_VIEW") || authorities.includes("PROVIDER_BOARD_CERTIFICATION_EDIT")
    || authorities.includes("PROVIDER_IMMUNIZATION_VIEW") || authorities.includes("PROVIDER_IMMUNIZATION_EDIT")
    || authorities.includes("PRACTICE_BASIC_VIEW") || authorities.includes("PRACTICE_BASIC_EDIT")
    || authorities.includes("KPI_VIEW") || authorities.includes("KPI_EDIT")
    || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT"))
  {
    let filteredReportPages = [];

    if (isH3Admin || authorities.includes("KPI_VIEW") || authorities.includes("KPI_EDIT"))
    {
      // KPI reports
      filteredReportPages.push(items[4].pages[0]);
    }

    if (isH3Admin || authorities.includes("PROVIDER_SENSITIVE_DETAILS_VIEW") || authorities.includes("PROVIDER_SENSITIVE_DETAILS_EDIT")
      || authorities.includes("PROVIDER_DOCUMENT_VIEW") || authorities.includes("PROVIDER_DOCUMENT_EDIT")
      || authorities.includes("PROVIDER_FACILITY_VIEW") || authorities.includes("PROVIDER_FACILITY_EDIT")
      || authorities.includes("PROVIDER_CME_VIEW") || authorities.includes("PROVIDER_CME_EDIT")
      || authorities.includes("PROVIDER_BOARD_CERTIFICATION_VIEW") || authorities.includes("PROVIDER_BOARD_CERTIFICATION_EDIT")
      || authorities.includes("PROVIDER_IMMUNIZATION_VIEW") || authorities.includes("PROVIDER_IMMUNIZATION_EDIT"))
    {
      // Provider reports
      filteredReportPages.push(items[4].pages[1]);
    }

    if (isH3Admin || authorities.includes("PRACTICE_BASIC_VIEW") || authorities.includes("PRACTICE_BASIC_EDIT"))
    {
      // Practice reports
      filteredReportPages.push(items[4].pages[2]);
    }

    if (isH3Admin || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT"))
    {
      // Task reports
      filteredReportPages.push(items[4].pages[3]);
    }

    // TODO new permissions
    // if (isH3Admin)
    // {
    //   // Audit reports
    //   filteredReportPages.push(items[4].pages[4]);
    // }

    var copy = {};
    Object.assign(copy, items[4]);
    copy.pages = filteredReportPages;

    filteredItems.push(copy);
  }

  // Has any admin
  if (isH3Admin || authorities.includes("ADMIN_PROVIDER_MANAGEMENT_VIEW") || authorities.includes("ADMIN_PROVIDER_MANAGEMENT_EDIT")
  || authorities.includes("ADMIN_PRACTICE_MANAGEMENT_VIEW") || authorities.includes("ADMIN_PRACTICE_MANAGEMENT_EDIT")
  || authorities.includes("ADMIN_USER_MANAGEMENT_VIEW") || authorities.includes("ADMIN_USER_MANAGEMENT_EDIT"))
  {
    let filteredAdminPages = [];

    // Provider management
    if (isH3Admin || authorities.includes("ADMIN_PROVIDER_MANAGEMENT_VIEW") || authorities.includes("ADMIN_PROVIDER_MANAGEMENT_EDIT"))
    {
      filteredAdminPages.push(items[5].pages[0]);
    }

    // Practice management
    if (isH3Admin || authorities.includes("ADMIN_PRACTICE_MANAGEMENT_VIEW") || authorities.includes("ADMIN_PRACTICE_MANAGEMENT_EDIT"))
    {
      filteredAdminPages.push(items[5].pages[1]);
    }

    // User management
    if (isH3Admin || authorities.includes("ADMIN_USER_MANAGEMENT_VIEW") || authorities.includes("ADMIN_USER_MANAGEMENT_EDIT"))
    {
      filteredAdminPages.push(items[5].pages[2]);
    }

    // Carrier management
    if (isH3Admin)
    {
      filteredAdminPages.push(items[5].pages[3]);
    }

    // Facility management
    if (isH3Admin)
    {
      filteredAdminPages.push(items[5].pages[4]);
    }

    var copy = {};
    Object.assign(copy, items[5]);
    copy.pages = filteredAdminPages;

    filteredItems.push(copy);
  }


  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <img alt="H3" src={logo} width={25} height={25} />
            &nbsp;
            <span className="align-middle me-3">H3 Elevate</span>
            <br /><br />
            <h5 className="align-middle me-3" style={{color: "#2B7A78", fontSize: "0.8em"}}>
              <RevenueTeamLabel />
            </h5>
          </a>

          <SidebarNav items={filteredItems} />
          {!!showFooter && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
