import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

import PrivilegesLookup from "../../components/privileges/PrivilegesLookup";

import logo from "../../assets/img/logo_dark.png";

const { REACT_APP_BACKEND_URL } = process.env;

const PrivilegesPage = () => {
  const [error, setError] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  let { by, token } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const inIframe = () => window.self !== window.top;

  var isSearch = searchParams.get("version") === "search";

  const switchVersions = (e) => {
    e.preventDefault();

    if (searchParams.get("version") === "search")
    {
      searchParams.delete("version");
      setSearchParams(searchParams);
    }
    else
    {
      searchParams.set("version", "search");
      setSearchParams(searchParams);
    }
  }

  useEffect(() => {
    if (token && (by === "provider" || by === "facility"))
    {
      fetch(REACT_APP_BACKEND_URL + "privileges/practice/" + token)
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }
    
          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          if (parseInt(error.message) === 400 || parseInt(error.message) === 404)
          {
            setNotFound(true);
          }

          setError(error);
        }
      )
    }
    else
    {
      setIsLoaded(true);
      setNotFound(true);
    }
  }, [token])

  if (!isLoaded)
  {
    return (
      <React.Fragment>
        <Helmet title="Provider Privileges Lookup | Loading..." />
        <div className="text-center mt-4">
          {!inIframe() ?
          <>
          <br /><br /><br />
          </>
          :<></>
          }
          <Card>
            <Card.Body>
              <p className="lead">Loading...</p>
              <br />
              <Spinner animation="border" size="sm" />
              <br /><br />
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (notFound)
  {
    return (
      <React.Fragment>
        <Helmet title="Provider Privileges Lookup | Not Found..." />
        <div className="text-center mt-4">
          {!inIframe() ?
          <>
          <br />
          <img
              src={logo}
              alt="H3"
              className="img-fluid"
              width="80"
              height="80"
            />
          <br /><br />
          <p className="lead"><b>Provider Privileges Lookup</b></p>
          </>
          :
          <></>
          }
          <Card>
            <Card.Body>
            <p className="lead">That link doesn't appear to be valid. Please make sure to copy and paste the full privileges lookup URL.</p>
            <p className="lead">If you're still having trouble, please reach out to the practice for assistance.</p>
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (error || !data)
  {
    return (
      <React.Fragment>
        <Helmet title="Provider Privileges Lookup | Something Went Wrong..." />
        <div className="text-center mt-4">
          {!inIframe() ?
          <>
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="80"
                  height="80"
                />
          <br /><br />
          <p className="lead"><b>Provider Privileges Lookup</b></p>
          <br />
          </>
          :<></>
          }
          <Card>
            <Card.Body>
            <p className="lead">Something went wrong; please reload the page...</p>
            <br />
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }

  let title = "Provider Privileges Lookup | " + data.name;
  return (
    <React.Fragment>
      <Helmet title={title} />
      <div className="text-center mt-4">
        {!inIframe() ?
        <>
        <img
                src={data.photoUrl == null || data.photoUrl === '' ? logo : data.photoUrl}
                alt="H3"
                className="img-fluid"
                width={data.photoUrl == null || data.photoUrl === '' ? 80 : 200}
                height={data.photoUrl == null || data.photoUrl === '' ? 80 : 200}
                style={{marginBottom: "10px"}}
              />
        <br />
        <p className="lead"><b>Provider Privileges Lookup | {data.name}</b></p>
        <br />
        </>
        :<></>
        }
      </div>

      <Card>
        <Card.Body style={{minHeight: "300px"}}>
          <>
          <div className="mb-3 float-end"></div>
          <div className="m-sm-4">
            <PrivilegesLookup by={by} token={token} />
          </div>
          </>
        </Card.Body>
        {/* <p><a onClick={(e) => {switchVersions(e)}} className="float-end" style={{marginRight: "20px"}}>Switch to {isSearch ? "Lookup" : "Search"}</a></p> */}
      </Card>
    </React.Fragment>
  )
  
};

export default PrivilegesPage;
