import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Table, Spinner, Form, Button, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import authenticatedFetch from "../../utils/fetch"
import NotyfContext from "../../contexts/NotyfContext";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";


const PracticeBasicFields = ({name, setName}) => {
  if (name == null)
  {
    name = "";
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
        <Card.Title>
        Practice Details
        </Card.Title>
      </Card.Header>
      <Card.Body>
    <Table>
      <tbody>
        <tr>
          <td>Name*:</td>
          <td><Form.Control type="input" value={name} onChange={(e) => {setName(e.target.value)}}/></td>
        </tr>
      </tbody>
    </Table>
    </Card.Body>
    <br />
  </Card>
  )
}


const AvailableHub= ({ id, name, isActive, onChange, canEdit }) => {
  const [localIsActive, setLocalIsActive] = useState(isActive);

  const handleIsActiveChanged = (e) => {
    setLocalIsActive(e.target.checked);
    onChange(id, e.target.checked);
  }

  return (
    <tr>
        <td>{name}</td>
        <td><Form.Check checked={localIsActive} disabled={!canEdit} onChange={(e) => {handleIsActiveChanged(e)}} />  </td>
        <td>{isActive && !localIsActive && <span className="text-warning" style={{fontSize: '0.85em'}}>Practice's portal login credentials will also be deleted!</span>}</td>
    </tr>
  );
}

const PracticeHubAssignment = ({practiceId, onHubChanged, canEdit, refresh}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: practiceId && practiceId > 0 ? "admin/practice/hubs/" + practiceId : "admin/practice/hubs/",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);

        for (var i = 0; i < result.length; i++)
        {
          onHubChanged(result[i].id, result[i].isActive);
        }
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [practiceId, refresh])

  var hubList;

  if (error)
  {
    hubList = <tr><td colSpan="4">Error... please try again...</td></tr>;
  }
  else if (!isLoaded)
  {
    hubList = <tr><td colSpan="4">Loading...</td></tr>;
  }
  else if (!data || data.length === 0)
  {
    hubList = <tr><td colSpan="4">No hubs found...</td></tr>;
  }
  else
  {
    hubList = data.map((hub) => {
      return (
        <AvailableHub key={hub.id} id={hub.id} name={hub.name} isActive={hub.isActive} onChange={onHubChanged} canEdit={canEdit}/>
      )
    }, this);
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
        <Card.Title>
        Data Hubs
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table striped hover>
          <thead>
            <tr>
              <td>Name</td>
              <td>Active</td>
              <td style={{width: '150px'}}>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {hubList}
          </tbody>
        </Table>
      </Card.Body>
  </Card>
  )
}


const PracticeCreation = ({onCancel, onSuccess}) => {
  const [name, setName] = useState("");

  const [localHubData, setLocalHubData] = useState({})

  const[isSaving, setIsSaving] = useState(false);

  const notyf = useContext(NotyfContext);

  const onHubChanged = (hubId, isActive) => {
    localHubData[hubId] = isActive;
    setLocalHubData(localHubData);
  }

  const handleSave = (e) => {
    e.preventDefault();

    if (isSaving)
    {
      return;
    }

    if (!name || name.length === 0)
    {
      alert("Practice name is required.")
      return;
    }

    setIsSaving(true);

    authenticatedFetch({
      method: "POST",
      path:  "admin/practice/",
      requestData: {
        name: name,
        dataHubs: localHubData
      },
      successHandler: function(result) {
        setIsSaving(false);
        notyf.open({type: "success", message: "Practice created!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        onSuccess();
      },
      errorHandler: function(error) {
        setIsSaving(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
    <>
      <Row>
        <Col lg="6">
          <PracticeBasicFields name={name} setName={setName} />
        </Col>
        <Col lg="6">
          <PracticeHubAssignment onHubChanged={onHubChanged} canEdit={true}/>
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg="12">
          { isSaving ?
            <Spinner animation="border" size="sm" variant="primary" style={{marginLeft: '15px'}}/>
          :
          <>
            <Button onClick={(e) => {handleSave(e)}}>Add</Button>
            &nbsp;&nbsp;
            <Button variant="secondary" onClick={(e) => {onCancel(e)}}>Cancel</Button>
          </>
          }
        </Col>
      </Row>
    </>
  )
};

const PracticeUpdate = ({practiceId, onCancel, canEdit, onRemove}) => {
  
  const[isSaving, setIsSaving] = useState(false);

  // Have to duplicate this to get the state up high enough
  const [localHubData, setLocalHubData] = useState({});
  const [forceRefresh, setForceRefresh] = useState(0);

  const notyf = useContext(NotyfContext);

  const onHubChanged = (hubId, isActive) => {
    localHubData[hubId] = isActive;
    setLocalHubData(localHubData);
  }

  const handleSave = (e) => {
    e.preventDefault();

    setIsSaving(true);

    authenticatedFetch({
      method: "PUT",
      path:  "admin/practice/" + practiceId,
      requestData: {
        dataHubs: localHubData,
      },
      successHandler: function(result) {
        setIsSaving(false);
        setForceRefresh(forceRefresh + 1);
        window.scrollTo(0, 0);
        notyf.open({type: "success", message: "Practice updated!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setIsSaving(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }
  
  return (
    <>
    <Row>
      <Col lg="6">
        <PracticeHubAssignment practiceId={practiceId} onHubChanged={onHubChanged} canEdit={canEdit} refresh={forceRefresh}/>
      </Col>
    </Row>
    <br />
    <Row>
      <Col lg="12">
        { isSaving ?
          <Spinner animation="border" size="sm" variant="primary" style={{marginLeft: '15px'}}/>
        :
        <>
          {canEdit && <Button onClick={(e) => {handleSave(e)}}>Save</Button>}
          &nbsp;&nbsp;
          <Button variant="secondary" onClick={(e) => {onCancel(e)}}>Cancel</Button>
        </>
        }
      </Col>
    </Row>
    </>
  );
};


const PracticePicker = ({onPracticeChanged, isLoaded, error, data}) => {
  var practiceList;
  if (!isLoaded)
  {
    practiceList = [<option value={0} key={0}>Loading...</option>];
  }
  else if (error || !data)
  {
    practiceList = [<option value={0} key={0}>Error... Please reload the page...</option>];
  }
  else
  {
    practiceList = [<option value={0} key={0}>Select a practice...</option>];
    practiceList.push(data.map((practice, i) => {
      return (
        <option value={practice.id} key={practice.id}>{practice.name}</option>
      )
    }, this));
  }

  return (
    <Row>
      <Col lg="12">
        <Form.Select onChange={(e) => {onPracticeChanged(e)}} style={{width: "500px"}} >
          {practiceList}
        </Form.Select>
      </Col>
    </Row>
  )
};

const PracticeManagement = () => {

  const [practiceId, setPracticeId] = useState(0);
  const [practiceName, setPracticeName] = useState(null);
  
  const [addingNewPractice, setAddingNewPractice] = useState(false);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const { isH3Admin, authorities, navbarRefresh, setNavbarRefresh } = useContext(UserSettingsContext);
  const canEdit = isH3Admin || authorities.includes("ADMIN_PRACTICE_MANAGEMENT_EDIT");

  useEffect(() => {
    reloadList();
  }, []);


  const reloadList= () => {
    authenticatedFetch({
      path: "admin/practice/practices",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  const onPracticeChanged = (e) => {
    e.preventDefault();
    setPracticeId(e.target.value);
    setPracticeName(e.target.options[e.target.selectedIndex].text)
  }

  const handleBack= (e) => {
    e.preventDefault();
    setPracticeId(0);
    setPracticeName(null);
    setAddingNewPractice(false);
  }

  const handleAddNewPractice= (e) => {
    e.preventDefault();
    setPracticeId(0);
    setPracticeName(null);
    setAddingNewPractice(true);
  }

  const handleCreateSuccess= () => {
    setPracticeId(0);
    setPracticeName(null);
    setAddingNewPractice(false);
    reloadList();
    setNavbarRefresh(navbarRefresh + 1);
  }

  const handleRemove= () => {
    setPracticeId(0);
    setPracticeName(null);
    setAddingNewPractice(false);
    reloadList();
    setNavbarRefresh(navbarRefresh + 1);
  }

  var title = "Practice Management";
  var helmetTitle = title;
  if (practiceId > 0)
  {
    title = <>Practice Management | <a href={"/practice/" + practiceId}>{practiceName}</a></>
    helmetTitle = "Practice Management | " + practiceName;
  }
  else if (addingNewPractice)
  {
    title = "Pratice Management | Add New Practice";
    helmetTitle = title;
  }

  return (
    <React.Fragment>
      <Helmet title={helmetTitle}/>
      <h1 className="h3 mb-3">{title} 
      
      {!addingNewPractice && practiceId === 0 && canEdit &&
      <Button className="float-end btn btn-primary" onClick={(e) => {handleAddNewPractice(e)}}>+ Add New Practice</Button>}
      
      </h1>
      
      {(practiceId > 0 && data.length > 1) || addingNewPractice ? <><Button variant="secondary" size="sm" onClick={(e) => {handleBack(e)}}>&lt; Back</Button><br /><br /></> : <></>}
      
      {addingNewPractice ? 
        <PracticeCreation onCancel={handleBack} onSuccess={handleCreateSuccess}/> 
        :
        <>
        <Container fluid className="p-0">
          { practiceId > 0 ? <></> : <PracticePicker isLoaded={isLoaded} error={error} data={data} onPracticeChanged={onPracticeChanged} /> }    
        </Container>

        {practiceId > 0 && <PracticeUpdate practiceId={practiceId} onCancel={handleBack} onRemove={handleRemove} canEdit={canEdit}/>}
      </>
      }
    </React.Fragment>
  )
};

export default PracticeManagement;
