const stateLicenseTypes = [
  {"value": "", "label": ""},
  {"value":"APN", "label":"APN"},
  {"value":"APRN", "label":"APRN"},
  {"value":"CADC", "label":"CADC"},
  {"value":"CSUDCI", "label":"CSUDCI"},
  {"value":"CSW", "label":"CSW"},
  {"value":"DO", "label":"DO"},
  {"value":"EMT", "label":"EMT"},
  {"value":"LCMHC", "label":"LCMHC"},
  {"value":"LCPC", "label":"LCPC"},
  {"value":"LCSW", "label":"LCSW"},
  {"value":"LPC", "label":"LPC"},
  {"value":"LSW", "label":"LSW"},
  {"value":"LSWP", "label":"LSW-P"},
  {"value":"MA", "label":"MA"},
  {"value":"MD", "label":"MD"},
  {"value":"MSW", "label":"MSW"},
  {"value":"NP", "label":"NP"},
  {"value":"NURSE_OTHER", "label":"Nurse (Other)"},
  {"value":"OTHER", "label":"Other"},
  {"value":"PA", "label":"PA"},
  {"value":"PC", "label":"PC"},
  {"value":"PT", "label":"PT"},
  {"value":"RESIDENT_TRAINING", "label":"Resident Training"},
  {"value":"RN", "label":"RN"}
];

export default stateLicenseTypes;