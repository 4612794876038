import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Table, Badge, Button, Spinner, Form } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import authenticatedFetch from "../../utils/fetch"
import Select from "react-select";
import NotyfContext from "../../contexts/NotyfContext";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { ExternalLink,  X, Check, Edit2, Trash2, Upload } from "react-feather";
import CopyToClipboardToggle from "../../utils/CopyToClipboardToggle";
import InputMask from "react-input-mask";
import getActivityDateTimeDisplay from "../../utils/datetime";

import doctor1 from "../../assets/img/avatars/logo_unknown_doctor.jpg";


const PracticeSelect = ({ handlePracticeChanged, selectedPractices, id, canAccessAllPractices}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
      authenticatedFetch({
          path: "user/admin/practices/" + id,
              successHandler: function(result) {
              setIsLoaded(true);
              setData(result);
          },
          errorHandler: function(error) {
              setIsLoaded(true);
              setError(error);
          }
      });
  }, [])

  const options = data.map((practice) => {
      return {value: practice.id, label: practice.name};
  });

  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
      return (
          <div style={{width: "225px", fontSize: "0.9em"}}>
              <Select
              options={options}
              onChange={(selectedPractices) => {
                handlePracticeChanged(selectedPractices);
              }}
              value={selectedPractices}
              placeholder={canAccessAllPractices ? "(can access all)" : "Select practice(s)..."}
              isMulti
              theme={(theme) => ({
                ...theme,
                colors: {
                ...theme.colors,
                  primary50: '#DEF2F1',
                  primary25: '#DEF2F1',
                  primary: '#3AAFA9',
                },
              })}
              />
          </div>
      )
  }
}


const User = ({ canEdit, isSelf, photoUrl, id, lastName, firstName, email, cellPhone, permissionGroups, availablePermissionChoices, availableRoles, restrictedToPractices, canAccessAllPractices, activePasswordResetUrl, lastLogin, refreshUserList, onSaved, onCancel, addingNew=false }) => {
  const [isGeneratingPasswordResetLink, setIsGeneratingPasswordResetLink] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editing, setEditing] = useState(addingNew);

  const [localPhotoUrl, setLocalPhotoUrl] = useState(photoUrl == null ? "" : photoUrl);
  const [localFirstName, setLocalFirstName] = useState(firstName == null ? "" : firstName);
  const [localLastName, setLocalLastName] = useState(lastName == null ? "" : lastName);
  const [localEmail, setLocalEmail] = useState(email == null ? "" : email);
  const [localCellPhone, setLocalCellPhone] = useState(cellPhone == null ? "" : cellPhone);

  const [localRole, setLocalRole] = useState("");
  const [localPermissionGroups, setLocalPermissionGroups] = useState(permissionGroups);

  const [localRestrictedToPractices, setLocalRestrictedToPractices] = useState(restrictedToPractices.map((practice) => {return {value: practice.id, label: practice.name}}));

  const [localSendWelcomeEmail, setLocalSendWelcomeEmail] = useState(addingNew);

  const [newPhotoFile, setNewPhotoFile] = useState(null);

  const notyf = useContext(NotyfContext);

  useEffect(() => {
    updateStandardRole();
  }, [id, localPermissionGroups])


  var allRegularEdit = true;
  var allRegularView = true;
  var allAdminEdit = true;
  var allAdminNone = true;
  const updateStandardRole = () => {
    Object.keys(localPermissionGroups).map((permissionGroupName) => {
      Object.keys(localPermissionGroups[permissionGroupName]).map((permissionName) => {
        if (localPermissionGroups[permissionGroupName][permissionName].toLowerCase().indexOf("edit") < 0)
        {
          if (permissionGroupName === "Admin")
          {
            allAdminEdit = false;
          }
          else
          {
            allRegularEdit = false;
          }
        }

        if (localPermissionGroups[permissionGroupName][permissionName].toLowerCase().indexOf("view") < 0)
        {
          if (permissionGroupName !== "Admin")
          {
            allRegularView = false;
          }
        }

        if (localPermissionGroups[permissionGroupName][permissionName].toLowerCase().indexOf("none") < 0)
        {
          if (permissionGroupName === "Admin")
          {
            allAdminNone = false;
          }
        }
      }, this);
    }, this);

    const isAdmin = allRegularEdit && allAdminEdit;
    const isEditAccessUser = allRegularEdit && allAdminNone;
    const isViewAccessUser = allRegularView && allAdminNone;

    if (isAdmin)
    {
      setLocalRole("ROLE_ADMIN");
    }
    else if (isEditAccessUser)
    {
      setLocalRole("ROLE_USER_EDIT");
    }
    else if (isViewAccessUser)
    {
      setLocalRole("ROLE_USER_VIEW");
    }
    else
    {
      setLocalRole("");
    }
  }

  const handleGeneratePasswordResetLinkClicked = (e) => {
    e.preventDefault();
    setIsGeneratingPasswordResetLink(true);

    authenticatedFetch({
      method: "POST",
      path: "auth/generateresetpasswordtoken/" + id,
      successHandler: function() {
        setIsGeneratingPasswordResetLink(false);
        refreshUserList();
        notyf.open({type: "success", message: "URL generated!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function() {
        setIsGeneratingPasswordResetLink(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const handleCopyPasswordResetLinkClicked = () => {
    // No op
  }

  const handleEditClicked = (e) => {
    e.preventDefault();
    setEditing(true);

    const element = document.getElementById("user-" + id);
    if (element) {
      element.scrollIntoView({behavior: "auto", block: "start", inline: "center"});
    }
  }

  const cancelEdit = (e) => {
    e.preventDefault();
    setEditing(false);
    onCancel();
    
    // Reset to original values
    setLocalFirstName(firstName);
    setLocalLastName(lastName);
    setLocalEmail(email);
    setLocalCellPhone(cellPhone);
    setLocalPhotoUrl(photoUrl);

    setLocalPermissionGroups(permissionGroups);
    setLocalRestrictedToPractices(restrictedToPractices.map((practice) => {return {value: practice.id, label: practice.name}}));
  }

  const saveEdit = (e) => {
    e.preventDefault();

    if (localFirstName === "" || localLastName === "" || localEmail === "")
    {
      alert("First Name, Last Name, and Email are required");
      return;
    }

    if (localRestrictedToPractices.length === 0 && !canAccessAllPractices)
    {
      alert("Please select at least one practice");
      return;
    }

    setEditing(false);
    setIsSaving(true);

    if (addingNew)
    {
      createUserDetails();
    }
    else
    {
      updateUserDetails();
    }
  }

  const updateUserDetails = () => {
    var formData = new FormData();
    formData.append("data", new Blob([JSON.stringify(
      {
        firstName: localFirstName,
        lastName: localLastName,
        email: localEmail,
        cellPhone: localCellPhone
      })], {
        type: "application/json"
    }));

    if (newPhotoFile != null)
    {
      formData.append("newPhotoFile", newPhotoFile);
    }

    authenticatedFetch({
      method: "PUT",
      path:  "user/admin/" + id + "/details",
      requestData: formData,
      formData: true,
      successHandler: function(result) {
        updatePermissions(id=id);
      },
      errorHandler: function(error) {
        setIsSaving(false);
        setEditing(true);
        if (error.message === "409")
        {
          notyf.open({type: "error", message: "A user with that email address already exists in the system; please use a different email", duration: 5000, dismissable: true, ripple: true});
        }
        else
        {
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      }
    });
  }

  const createUserDetails = () => {
    var formData = new FormData();
    formData.append("data", new Blob([JSON.stringify(
      {
        sendWelcomeEmail: localSendWelcomeEmail,
        firstName: localFirstName,
        lastName: localLastName,
        email: localEmail,
        cellPhone: localCellPhone
      })], {
        type: "application/json"
    }));

    if (newPhotoFile != null)
    {
      formData.append("newPhotoFile", newPhotoFile);
    }

    authenticatedFetch({
      method: "POST",
      path:  "user/admin/details",
      requestData: formData,
      formData: true,
      successHandler: function(result) {
        updatePermissions(id=result.id);
      },
      errorHandler: function(error) {
        setIsSaving(false);
        setEditing(true);

        if (error.message === "409")
        {
          notyf.open({type: "error", message: "A user with that email address already exists in the system; please use a different email", duration: 5000, dismissable: true, ripple: true});
        }
        else
        {
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      }
    });
  }

  const updatePermissions = (id) => {
    authenticatedFetch({
      method: "PUT",
      path:  "user/admin/" + id + "/permissions",
      requestData: {
        permissionGroups: localPermissionGroups,
        practiceRestrictions: localRestrictedToPractices.map((practice) => {return practice.value}),
      },
      successHandler: function(result) {
        setIsSaving(false);
        refreshUserList();
        onSaved();
        notyf.open({type: "success", message: addingNew ? "User added!" : "User updated!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setIsSaving(false);
        setEditing(true);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const handleDeleteClicked = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this user?"))
    {
      setIsSaving(true);
      authenticatedFetch({
        method: "DELETE",
        path: "user/admin/" + id,
        successHandler: function(result) {
          setIsSaving(false);
          refreshUserList();
          notyf.open({type: "success", message: "User deleted!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function(error) {
          setIsSaving(false);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
  }

  const handlePermissionChanged = (e, permissionGroupName, permissionName) => {
    e.preventDefault();
    const copy = JSON.parse(JSON.stringify(localPermissionGroups));
    copy[permissionGroupName][permissionName] = e.target.value;
    setLocalPermissionGroups(copy);
  }

  const handleCommonRoleChanged = (e) => {
    e.preventDefault();
    setLocalRole(e.target.value);
  
    if (e.target.value === "ROLE_USER_VIEW")
    {
      const copy = JSON.parse(JSON.stringify(localPermissionGroups));
      Object.keys(copy).map((permissionGroupName) => {
        Object.keys(copy[permissionGroupName]).map((permissionName) => {
          copy[permissionGroupName][permissionName] = "VIEW";
        }, this);
      }, this); 

      Object.keys(localPermissionGroups["Admin"]).map((permissionName) => {
        copy["Admin"][permissionName] = "NONE";
      }, this);

      setLocalPermissionGroups(copy);
    }
    else if (e.target.value === "ROLE_USER_EDIT")
    {
      const copy = JSON.parse(JSON.stringify(localPermissionGroups));
      Object.keys(copy).map((permissionGroupName) => {
        Object.keys(copy[permissionGroupName]).map((permissionName) => {
          copy[permissionGroupName][permissionName] = "EDIT";
        }, this);
      }, this);

      Object.keys(localPermissionGroups["Admin"]).map((permissionName) => {
        copy["Admin"][permissionName] = "NONE";
      }, this);
      
      setLocalPermissionGroups(copy);
    }
    else if (e.target.value === "ROLE_ADMIN")
    {
      const copy = JSON.parse(JSON.stringify(localPermissionGroups));
      Object.keys(copy).map((permissionGroupName) => {
        Object.keys(copy[permissionGroupName]).map((permissionName) => {
          copy[permissionGroupName][permissionName] = "EDIT";
        }, this);
      }, this);
      setLocalPermissionGroups(copy);
    }
  }

  const handlePracticeChanged = (practices) => {
    setLocalRestrictedToPractices(practices);
  }

  const handleCellPhoneChanged = (phone) => {
    setLocalCellPhone(phone.replace(/_/g, ''));
  }

  const handleProfilePhotoAdded = (e) => {
    if (e.target.files && e.target.files.length === 1)
    {
      setNewPhotoFile(e.target.files[0]);
      setLocalPhotoUrl(URL.createObjectURL(e.target.files[0]));
    }
  }

  const handleUploadClicked = (e) => {
    e.preventDefault();

    document.getElementById('file-upload-' + id).click();
  }

  var permissionsBadges;
  if (localPermissionGroups)
  {
    permissionsBadges = Object.keys(localPermissionGroups).map((permissionGroupName) => {
      return (
        <li key={permissionGroupName}>
          <b>{permissionGroupName}:</b>&nbsp;
          {Object.keys(localPermissionGroups[permissionGroupName]).map((permissionName) => {
            if (localPermissionGroups[permissionGroupName][permissionName].toLowerCase().indexOf("none") >= 0)
            {
              return <span key={permissionName}></span>
            }
            
            return (
              <span key={permissionName}>
                <Badge bg={localPermissionGroups[permissionGroupName][permissionName].toLowerCase().indexOf("edit") >= 0 ? "primary" : "secondary"} pill>{permissionName}</Badge>&nbsp;
              </span>
            )
          }, this)}
        </li>
      )
    }, this);
  }

  var rolesOptions;
  if (availableRoles)
  {
    rolesOptions = Object.keys(availableRoles).map((roleName) => {
      return (
        <option value={roleName} key={roleName}>{availableRoles[roleName]}</option>
      )
    }, this);
  }

  var permissionsEdit;
  if (localPermissionGroups)
  {
    permissionsEdit = Object.keys(localPermissionGroups).map((permissionGroupName) => {
      return (
        <Table key={permissionGroupName} size="sm" striped hover bordered style={{background: "#FFFFFF"}} >
          <thead>
            <tr>
              <td style={{marginTop: "0", marginBottom: "0", fontSize: "0.9em"}}>
                <b>{permissionGroupName}</b>
              </td>
              <td style={{marginTop: "0", marginBottom: "0", fontSize: "0.9em"}}>
                <b>Access Level</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {Object.keys(localPermissionGroups[permissionGroupName]).map((permissionName) => {
              let choices = availablePermissionChoices[permissionGroupName][permissionName].map((choice) => {
                return (<option value={choice} key={choice}>{String(choice).charAt(0).toUpperCase() + String(choice.toLowerCase()).slice(1)}</option>)
              }, this);
              
              return(
                  <tr key={permissionName}>
                    <td style={{paddingTop: "2px", paddingBottom: "2px"}}>
                      <Form.Label style={{marginTop: "0", marginBottom: "0", fontSize: "0.9em"}}>{permissionName}</Form.Label>
                    </td>
                    <td style={{paddingTop: "2px", paddingBottom: "2px", fontSize: "0.9em", width: "125px"}}>
                      <Form.Select style={{fontSize: "0.9em"}} size="sm"  onChange={(e) => {handlePermissionChanged(e, permissionGroupName, permissionName)}} value={localPermissionGroups[permissionGroupName][permissionName]}>
                        {choices}
                      </Form.Select>
                    </td>
                  </tr>
              );
            }, this)}
          </tbody>
        </Table>
      )
    }, this);
  }

  var restrictedToPracticesBadges;
  if (localRestrictedToPractices)
  {
    restrictedToPracticesBadges = localRestrictedToPractices.map((practice, i) => {
      return (
        <span key={i}>
          <Badge bg="warning" pill>{practice["label"]}</Badge>&nbsp;
        </span>
      )
    }, this);
  }
  
  return (
    <tr id={"user-" + id}>
        <td>
          <div style={{textAlign: "center"}}>
            <img
                src={localPhotoUrl === '' ? doctor1 : localPhotoUrl}
                width="48"
                height="48"
                className="rounded-circle me-2"
                alt="Avatar"
              />
          </div>
          {editing &&
          <div style={{textWrap: "nowrap", marginTop: "7px"}}>
            <Button variant="primary" size="sm" onClick={(e) => {handleUploadClicked(e)}}><Upload width={16} height={16}/>&nbsp;Upload</Button>
            <Form.Control id={"file-upload-" + id} type="file" accept="image/*" style={{display: "none"}} onChange={(e) => handleProfilePhotoAdded(e)}/>
          </div>
          }
        </td>
        {editing ?
        <td>
          <Form.Control placeholder="First" type="input" style={{display: "inline", width: "95px"}} value={localFirstName} onChange={(e) => {setLocalFirstName(e.target.value)}}/>
          <Form.Control placeholder="Last" type="input" style={{display: "inline", width: "95px"}} value={localLastName} onChange={(e) => {setLocalLastName(e.target.value)}}/>
        </td>
        :
        <td>{localLastName + ", "}{localFirstName}</td>
        }
        {editing ?
        <td>
          <Form.Control type="input" value={localEmail} onChange={(e) => {setLocalEmail(e.target.value)}}/>
        </td>
        :
        <td style={{maxWidth: "210px"}}>{localEmail}</td>
        }
        {editing ?
        <td style={{minWidth: "150px"}}>
          <InputMask
            // style={{height: "38px", width: "95%"}}
            mask='(999) 999-9999'
            value={localCellPhone} 
            onChange={(e) => {handleCellPhoneChanged(e.target.value)}} >
            {(inputProps) => <Form.Control {...inputProps} type="text" />}
          </InputMask>
        </td>
        :
        <td style={{fontFamily: "monospace", whiteSpace: "nowrap"}}>{localCellPhone}</td>
        }
        {editing ?
        <td style={{minWidth: "260px", height: "400px"}}>
          <div style={{width: "100%", height: "100%", overflowY: "scroll"}}>
          {availableRoles && Object.keys(availableRoles).length > 0 ?
          <>
          <Form.Select size="sm" style={{width:"auto", display: "inline", marginBottom: "7px"}} value={localRole} onChange={(e) => {handleCommonRoleChanged(e)}}>
            <option value="" key="">Select a typical role...</option>
            {rolesOptions}
          </Form.Select>&nbsp;&nbsp;or...&nbsp;
          <br />
          </> : <></>}
          {permissionsEdit}
          </div>
        </td>
        :
        <td>
          <ul style={{paddingLeft: "0"}}>{permissionsBadges}</ul>
        </td>
        }
        {editing ?
        <td><PracticeSelect handlePracticeChanged={handlePracticeChanged} selectedPractices={localRestrictedToPractices} id={id} canAccessAllPractices={canAccessAllPractices}/></td>
        :
        <td>
          { localRestrictedToPractices && localRestrictedToPractices.length > 0 ?
          <>{restrictedToPracticesBadges}</>
          :<></>
          }
        </td>
        }
        <td>{lastLogin ? getActivityDateTimeDisplay(lastLogin) : "(n/a)"}</td>
        {canEdit && !editing && !isSelf ?
        <>
          {isGeneratingPasswordResetLink ?
          <td><Spinner animation="border" size="sm" variant="primary" style={{marginLeft: '15px'}}/></td>
          :
          <td>{activePasswordResetUrl != null && activePasswordResetUrl.length > 0 ? <><a href={activePasswordResetUrl} target="_blank"><ExternalLink style={{'height': '16px', 'width': '16px'}}/></a>&nbsp;&nbsp;<CopyToClipboardToggle text={activePasswordResetUrl} onCopyValue={handleCopyPasswordResetLinkClicked} placement="bottom"/></>: <Button onClick={(e) => {handleGeneratePasswordResetLinkClicked(e)}} size="sm">Generate</Button>}</td>
          }
        </>
        : 
        (editing && addingNew ? 
          <td><Form.Label><Form.Check type="switch" checked={localSendWelcomeEmail} onChange={(e) => {setLocalSendWelcomeEmail(e.target.checked)}} />Send Welcome Email</Form.Label></td>
          : <td>&nbsp;</td>)
        }
        {canEdit &&
        <td>
        {isSelf ? <>&nbsp;</> :
        (isSaving ? <Spinner animation="border" size="sm" variant="primary" style={{marginLeft: '15px'}}/> :
        (editing ? 
        <span style={{whiteSpace: "nowrap"}}>
          <X style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {cancelEdit(e)}} />
          &nbsp;<Check style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {saveEdit(e)}} className="text-success"/>
        </span>
        :
        <span style={{whiteSpace: "nowrap"}}>
          <Edit2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleEditClicked(e)}}/>&nbsp;&nbsp;
          <Trash2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleDeleteClicked(e)}}/>
        </span>))
        }
        </td>
        }
    </tr>
  );
}

const UserList = ({canEdit, addingNew, onAdded, onCancelled}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    refreshUserList();
  }, [])

  const refreshUserList = () => {
    authenticatedFetch({
      path: "user/admin/revenueteam/",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  const onSaved = () => {
    if (addingNew)
    {
      onAdded();
    }
  }

  const onCancel = () => {
    if (addingNew)
    {
      onCancelled();
    }
  }

  var userList;

  if (error)
  {
    userList = <tr><td colSpan="8">Error... please try again...</td></tr>;
  }
  else if (!isLoaded)
  {
    userList = <tr><td colSpan="8">Loading...</td></tr>;
  }
  else if (!data || !data.users || data.users.length === 0)
  {
    userList = <tr><td colSpan="8">No users found...</td></tr>;
  }
  else
  {
    userList = data.users.map((user) => {
      return (
        <User key={user.id} canEdit={canEdit} isSelf={user.isSelf} id={user.id} photoUrl={user.photoUrl} lastName={user.lastName} firstName={user.firstName} email={user.email} cellPhone={user.cellPhone} permissionGroups={user.permissionGroups} availablePermissionChoices={user.availablePermissionChoices} availableRoles={user.availableRoles} restrictedToPractices={user.restrictedToPractices} canAccessAllPractices={user.canAccessAllPractices} activePasswordResetUrl={user.activePasswordResetUrl} lastLogin={user.lastLogin} refreshUserList={refreshUserList} onSaved={onSaved} onCancel={onCancel}/>
      )
    }, this);
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
      </Card.Header>
      <Table striped>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td>Name</td>
            <td>Email</td>
            <td>Cell Phone</td>
            <td>Permissions</td>
            <td>Restricted to Practice(s)</td>
            <td>Last Login</td>
            {canEdit && <td>Active Password Reset Link</td>}
            {canEdit && <td>&nbsp;</td>}
          </tr>
        </thead>
        <tbody>
          {canEdit && addingNew && data && data.newUserShell != null &&
            <User key={0} addingNew={addingNew} canEdit={canEdit} isSelf={data.newUserShell.isSelf} id={0} photoUrl={data.newUserShell.photoUrl} lastName={data.newUserShell.lastName} firstName={data.newUserShell.firstName} email={data.newUserShell.email} cellPhone={data.newUserShell.cellPhone} permissionGroups={data.newUserShell.permissionGroups} availablePermissionChoices={data.newUserShell.availablePermissionChoices} availableRoles={data.newUserShell.availableRoles} restrictedToPractices={data.newUserShell.restrictedToPractices} canAccessAllPractices={data.newUserShell.canAccessAllPractices} activePasswordResetUrl={data.newUserShell.activePasswordResetUrl} lastLogin={data.newUserShell.lastLogin} refreshUserList={refreshUserList} onSaved={onSaved} onCancel={onCancel}/>
          }
          {userList}
        </tbody>
      </Table>
    </Card>
  )
};


const UserManagement = () => {
  const [addingNew, setAddingNew] = useState(false);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);
  const canEdit = isH3Admin || authorities.includes("ADMIN_USER_MANAGEMENT_EDIT");

  const handleAddNewClicked = (e) => {
    e.preventDefault();
    setAddingNew(true);
  }

  const onAdded = () => {
    setAddingNew(false);
  }

  const onCancelled = () => {
    setAddingNew(false);
  }

  return (
    <Row>
      <Helmet title="Users"/>
      <h1 className="h3 mb-3">User Management
      {!addingNew && canEdit &&
      <Button className="float-end btn btn-primary" onClick={(e) => {handleAddNewClicked(e)}}>+ Add New User</Button>}

      </h1>
      <Col lg="12">
        <UserList canEdit={canEdit} addingNew={addingNew} onAdded={onAdded} onCancelled={onCancelled}/>
      </Col>
    </Row>
  )
};

export default UserManagement;
