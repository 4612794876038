import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, Button, Form, OverlayTrigger, Tooltip, Badge, Accordion} from "react-bootstrap";
import { useParams } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import Spinner from 'react-bootstrap/Spinner';
import NotyfContext from "../contexts/NotyfContext";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import { Key, Eye, CheckCircle, Clock, XCircle, ExternalLink, X, Check, Edit2, Trash2, Download, FileText, AlertTriangle, UserCheck} from "react-feather";
import Select from "react-select";

import { useTable, useSortBy, useFilters} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faFilePdf,
  faFileCsv
} from "@fortawesome/free-solid-svg-icons";

import authenticatedFetch from "../utils/fetch"

import DocumentList from "../utils/DocumentList"
import EditableDetail from "../utils/EditableDetail"
import LoginCredentialsModal from "../utils/LoginCredentialsModal"
import LinesOfBusinessModal from "../utils/LinesOfBusinessModal"
import ContactInfoPopover from "../utils/ContactInfoPopover";
import EditableExpires from "../utils/EditableExpires";
import EditableExpiresControls from "../utils/EditableExpiresControls";
import HubParticipationStatusTable from "../utils/HubParticipationStatusTable"
import FacilityParticipationStatusTable from "../utils/FacilityParticipationStatusTable";
import OpenTasks from "../utils/OpenTasks";
import EditableAddress from "../utils/EditableAddress";
import ReadOnlyAddress from "../utils/ReadOnlyAddress";
import PeerReferences from "../utils/PeerReferences";
import Trainings from "../utils/Training";
import WorkHistories from "../utils/WorkHistory";
import StateLicenses from "../utils/StateLicenses";
import MalpracticeInsurances from "../utils/MalpracticeInsurance";
import BoardCertifications from "../utils/BoardCertification";
import Immunizations from "../utils/Immunizations";

import getActivityDateTimeDisplay from "../utils/datetime"
import rawCMECategories from "../utils/cmeCategories";

import doctor1 from "../assets/img/avatars/logo_unknown_doctor.jpg";
import MaritalStatusSelect from "../utils/MaritalStatusSelect";
import rawMaritalStatuses from "../utils/maritalStatuses";


const ProviderOnboardingUrl = ({providerId}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);


  useEffect(() => {
    authenticatedFetch({
      path: "provider/onboarding/url/" + providerId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [providerId])


  if (!error && isLoaded && data && data.hasActiveOnboardingUrl)
  {
    return <h5 className="text-warning"><AlertTriangle style={{'height': '18px', 'width': '18px'}}/>&nbsp;This provider is actively completing their onboarding forms. They have the ability to edit data from various sections of their profile, including peer references, work history, etc.</h5>
  }

  return <></>;
}

const ParticipationStatusToggle = ({ handleTinSelected, practices }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  let practiceList = practices && practices.length > 0
		&& practices.map((practice, i) => {
		return (
			<option key={i} value={practice.id}>{practice.tin}&nbsp;[{practice.name}]</option>
		)
	}, this);

  if (!isLoaded && practices && practices.length > 0)
  {
    // Fire for the initial selection
    setIsLoaded(true);
    handleTinSelected(practices[0].id);
  }
  
  return (
    <>
      <Form.Label style={{fontSize: '0.8em'}}>for TIN&nbsp;&nbsp;</Form.Label>
      <Form.Select onChange={(e) => {handleTinSelected(e.target.value, e)}} style={{width: '80%', display:'inline'}}>
        {practiceList}
      </Form.Select>
    </>
  );
}

const CarrierParticipation = ({ onSave, carrierId, providerId, practiceId, practiceName, providerName, carrierName, phone, fax, email, expires, effectiveDate, portal, active=true, pending=false, notApplicable=false, showNa=false}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [showLinesOfBusiness, setShowLinesOfBusiness] = useState(false);
  const handleShowLinesOfBusiness = () => setShowLinesOfBusiness(true);
  const handleCloseLinesOfBusiness = () => setShowLinesOfBusiness(false);

  const [editingExpires, setEditingExpires] = useState(false);
  const [localExpires, setLocalExpires] = useState(expires);
  const [localEffectiveDate, setLocalEffectiveDate] = useState(effectiveDate);
  const [localActive, setLocalActive] = useState(active);
  const [localNotApplicable, setLocalNotApplicable] = useState(notApplicable);
  
  const notyf = useContext(NotyfContext);
  const { isH3Admin, authorities } = useContext(UserSettingsContext);

  useEffect(() => {
    setLocalActive(active);
    setLocalNotApplicable(notApplicable);
    setLocalExpires(expires);
    setLocalEffectiveDate(effectiveDate);
    setEditingExpires(false);
  }, [practiceId, carrierId, providerId, active, notApplicable, expires, effectiveDate]); // should only need practiceId here (for handling the TIN toggle), but be safe

  function handleClick(portal, e) {
    e.preventDefault();
    window.open(portal, 'newwindow', 'width=800,height=600');
    handleShow();
  }

  const handleEditExpiresClicked = (e) =>
  {
    e.preventDefault();
    setEditingExpires(true);
  }

  const saveExpires = (e) =>
  {
    e.preventDefault();
    setEditingExpires(false);

    authenticatedFetch({
      path: "provider/participationstatus/" + providerId + "/" + practiceId + "/" + carrierId,
      method: "POST",
      requestData: {active: localActive, notApplicable: localNotApplicable, expiration: localExpires, effectiveDate: localEffectiveDate},
      successHandler: function() {
        onSave(practiceId); // easiest way to update expires (so undo works the next time) is just to have parent re-render us

        notyf.open({type: "success", message: "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function() {
        setLocalActive(active);
        setLocalNotApplicable(notApplicable);
        setLocalExpires(expires);
        setLocalEffectiveDate(effectiveDate);
        alert("Something went wrong; please try again");
      }
    });
  }

  const cancelEditExpires = (e) => {
    e.preventDefault();
    setEditingExpires(false);
    setLocalActive(active);
    setLocalNotApplicable(notApplicable);
    setLocalExpires(expires);
    setLocalEffectiveDate(effectiveDate);
  }

  const handleActiveChanged = (e) => {
    e.preventDefault();

    if (e.target.value === "INACTIVE")
    {
      // Make it clear that these are going to get deleted
      setLocalExpires(null);
      setLocalEffectiveDate(null);
      
      setLocalActive(false);
      setLocalNotApplicable(false);
    }
    else if (e.target.value === "ACTIVE")
    {
      setLocalActive(true);
      setLocalNotApplicable(false);
    }
    else if (e.target.value === "NOT_APPLICABLE")
    {
      // Make it clear that these are going to get nulled out
      setLocalExpires(null);
      setLocalEffectiveDate(null);

      setLocalActive(false);
      setLocalNotApplicable(true);
    }
  }

  const handleExpiresChanged = (date, e) => {
    e.preventDefault();
  
    // If they clear it out, date picker sends it back as "", not null
    if (date === "")
    {
      date = null;
    }

    setLocalExpires(date);
  }

  const handleEffectiveDateChanged = (date, e) => {
    e.preventDefault();
  
    // If they clear it out, date picker sends it back as "", not null
    if (date === "")
    {
      date = null;
    }

    setLocalEffectiveDate(date);
  }

  var activeSelectValue = "INACTIVE";
  if (localActive)
  {
    activeSelectValue = "ACTIVE";
  }
  else if (localNotApplicable)
  {
    activeSelectValue = "NOT_APPLICABLE"; 
  }

  let showCarrier = showNa || !notApplicable;

  return (
    <tr style={{display: showCarrier ? "" : "none"}}>
        <td style={{maxWidth: '120px', paddingRight: '0'}}>
          {portal == null || portal === '' || !(isH3Admin || authorities.includes("PROVIDER_PAYOR_PORTAL_EDIT") || authorities.includes("PROVIDER_PAYOR_PORTAL_VIEW"))
            ?
            <ContactInfoPopover name={carrierName} phone={phone} fax={fax} email={email} />
            :
            <a href="#" onClick={(e) => handleClick(portal, e)}>
              <ExternalLink style={{'height': '12px', 'width': '12px'}}/>&nbsp;<ContactInfoPopover name={carrierName} phone={phone} fax={fax} email={email} />
            </a>
          }
        </td>
        {(isH3Admin || authorities.includes("PROVIDER_PAYOR_PORTAL_EDIT") || authorities.includes("PROVIDER_PAYOR_PORTAL_VIEW")) ?
        <>
        <td><Key style={{cursor: 'pointer'}}  onClick={(e) => handleShow()}/></td>
        </>
        : <></>
        }
        <td>
        {editingExpires && (isH3Admin || authorities.includes("PROVIDER_PAYOR_PORTAL_EDIT"))
        ?
        <Form.Select onChange={(e) => {handleActiveChanged(e)}} value={activeSelectValue}>
          <option value="INACTIVE" key="INACTIVE">Inactive</option>
          <option value="ACTIVE" key="ACTIVE">Active</option>
          <option value="NOT_APPLICABLE" key="NOT_APPLICABLE">(n/a)</option>
        </Form.Select>
        :  
        localNotApplicable ? <>(n/a)</> : (pending ? <Clock className="text-warning" />: (localActive ? <CheckCircle className="text-success" /> : <XCircle className="text-danger" />))
        }
        </td>
        <td>
          {localActive && !localNotApplicable ? <Eye style={{cursor: 'pointer'}}  onClick={(e) => handleShowLinesOfBusiness()}/> : <></>}
        </td>
        <td>
          <EditableExpires warnWithColors={false} localExpires={localEffectiveDate} localActive={localActive && !localNotApplicable} editingExpires={editingExpires} handleExpiresChanged={handleEffectiveDateChanged} />
        </td>
        <td>
          <EditableExpires localExpires={localExpires} localActive={localActive && !localNotApplicable} editingExpires={editingExpires} handleExpiresChanged={handleExpiresChanged} />
        </td>
        <td>
          {isH3Admin || authorities.includes("PROVIDER_PAYOR_PORTAL_EDIT") ?
            <EditableExpiresControls editingExpires={editingExpires} cancelEditExpires={cancelEditExpires} saveExpires={saveExpires} handleEditExpiresClicked={handleEditExpiresClicked} />
          : <>&nbsp;</>
          }
        </td>
        {(isH3Admin || authorities.includes("PROVIDER_PAYOR_PORTAL_EDIT") || authorities.includes("PROVIDER_PAYOR_PORTAL_VIEW")) ?
        <LoginCredentialsModal canEdit={isH3Admin || authorities.includes("PROVIDER_PAYOR_PORTAL_EDIT")} show={show} onClose={handleClose} entityType="provider" entityId={providerId} entityName={providerName} carrierName={carrierName} carrierId={carrierId} />
        :<></>
        }
        <LinesOfBusinessModal practiceId={practiceId} providerId={providerId} carrierId={carrierId} practiceName={practiceName} providerName={providerName} carrierName={carrierName} show={showLinesOfBusiness} onClose={handleCloseLinesOfBusiness} />
    </tr>
  );
};

const CarrierParticipationStatusTable = ({providerId, providerName, practices}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [showNa, setShowNa] = useState(false);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);

  const notyf = useContext(NotyfContext);

  function handleTinSelected(practiceId, e) {
    if (e != null) {
      e.preventDefault();
    }

    authenticatedFetch({
      path: "provider/participationstatus/" + providerId + "/" + practiceId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  const handleExportClicked = (e) => {
    e.preventDefault();

    setIsExporting(true);

    authenticatedFetch({
      method: "GET",
      path:  "report/participationstatus/provider/" + providerId,
      successHandler: function(result) {
        window.open(result.url);
        setIsExporting(false);
      },
      errorHandler: function(error) {
        setIsExporting(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  var carrierList;

  if (error)
  {
    carrierList = <tr><td colSpan="6">Something went wrong... please reload the page...</td></tr>;
  }
  else if (practices == null || practices.length === 0)
  {
    carrierList = <tr><td colSpan="6">(n/a)</td></tr>;
  }
  else if (!isLoaded)
  {
    carrierList = <tr><td colSpan="6">Loading...</td></tr>;
  }
  else
  {
    carrierList = data.carriers && data.carriers.length > 0
      && data.carriers.map((carrier, i) => {
      return (
        <CarrierParticipation key={carrier.id} onSave={handleTinSelected} showNa={showNa} carrierId={carrier.id} practiceId={carrier.practiceId} practiceName={carrier.practiceName} providerId={providerId} providerName={providerName} carrierName={carrier.name} portal={carrier.portalUrl} phone={carrier.phone} email={carrier.email} fax={carrier.fax} active={carrier.isActive} pending={carrier.isPending} notApplicable={carrier.isNotApplicable} expires={carrier.expires} effectiveDate={carrier.effectiveDate} />
      )
    }, this);
  }

  if (!error && isLoaded && (!practices || practices.length <= 1)  && (!carrierList || carrierList.length === 0))
  {
    // Single practice with no carriers, hide this completely
    return <></>
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <div className="row">
            <div className="col-sm-4">
            Participation Status
            <br />
            { isExporting ? 
              <Spinner animation="border" size="sm" variant="primary" style={{marginLeft: '4px'}}/>
              :
              <Form.Label onClick={(e) => {handleExportClicked(e)}} style={{cursor: "pointer", display: "inline", fontSize: "0.8em", fontWeight: "normal", color: '#2B7A78', marginTop: "7px", marginRight: "3px"}}>
                <FontAwesomeIcon icon={faFilePdf} size="lg" style={{color: '#2B7A78'}}/>
                &nbsp;
                Export{(practices && practices.length > 1) && " all TINs"}
              </Form.Label>
            }
            </div>
            <div className="col-sm-8 text-end">
              <ParticipationStatusToggle handleTinSelected={handleTinSelected} practices={practices} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 text-end">
            {showNa ? 
              <Form.Label onClick={() => {setShowNa(false)}} style={{cursor: "pointer", display: "inline", fontSize: "0.8em", fontWeight: "normal", color: '#2B7A78', marginTop: "7px", marginRight: "5px"}} className="float-end">
                - Hide (n/a)
              </Form.Label>
              :
              <Form.Label onClick={() => {setShowNa(true)}} style={{cursor: "pointer", display: "inline", fontSize: "0.8em", fontWeight: "normal", color: '#2B7A78', marginTop: "7px", marginRight: "5px"}} className="float-end">
                + Show (n/a)
              </Form.Label>
              }
            </div>
          </div>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table striped hover>
          <thead>
            <tr>
              <td>Payor</td>
              {(isH3Admin || authorities.includes("PROVIDER_PAYOR_PORTAL_EDIT") || authorities.includes("PROVIDER_PAYOR_PORTAL_VIEW")) ?
              <>
              <td>Portal</td>
              </>
              : <></>
              }
              <td>Active</td>
              <td>Lines of Business</td>
              <td>Effective Date</td>
              <td>Expires</td>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {carrierList}   
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const ProviderCME = ({providerId, providerFormattedName, canEdit}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [addingNewCme, setAddingNewCme] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const notyf = useContext(NotyfContext);

  useEffect(() => {
    refreshCMEList();
  }, [providerId])

  function refreshCMEList()
  {
    setAddingNewCme(false);
    authenticatedFetch({
      path: "education/provider/" + providerId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function() {
        setIsLoaded(true);
        setError(true);
      }
    });
  }

  const handleAddCMEClicked = (e) => {
    e.preventDefault();
  
    setAddingNewCme(true);
  }

  const onCancelAddNewCme = () => {
    setAddingNewCme(false);
  }

  const handleExportClicked = (e) => {
    e.preventDefault();
    setIsExporting(true);

    // Very non-react way of doing this, but just easier than massively restructuing who owns this state
    const dateRangeOption = document.getElementById('cme-date-range-select').value;

    authenticatedFetch({
      method: "GET",
      path:  "education/export/provider/" + providerId + "?dateRangeOption=" + dateRangeOption,
      successHandler: function(result) {
        window.open(result.url);
        setIsExporting(false);
      },
      errorHandler: function(error) {
        setIsExporting(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  var cmeList = [];
  if (isLoaded)
  {
    cmeList = data.map((cme, i) => {
      return {key: cme.id, id: cme.id, title: cme.title, hours: cme.hours, dollarAmount: cme.dollarAmount, category: cme.category, eventDate: cme.eventDate, documentFilename: cme.documentFilename, updated: cme.updated, updatedBy: cme.updatedBy}
    }, this);
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-20px'}}>
        <Card.Title>CMEs
          {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '12px', marginTop: '4px'}}/>
            :
            <Form.Label onClick={(e) => {handleExportClicked(e)}} style={{cursor: "pointer", display: "inline", fontSize: "0.8em", fontWeight: "normal", color: '#2B7A78', marginTop: "7px", marginRight: "3px"}} className="float-end">
              <FontAwesomeIcon icon={faFilePdf} size="lg" style={{color: '#2B7A78'}}/>
              &nbsp;
              Export
            </Form.Label>
          }
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <ProviderCMETable cmeList={cmeList} addingNewCme={addingNewCme} onCancelAddNewCme={onCancelAddNewCme} providerId={providerId} error={error} isLoaded={isLoaded} canEdit={canEdit} refreshCMEList={refreshCMEList}/>
        {canEdit?
          <span className="float-end" style={{marginTop: '6px'}}>
            <Button size="sm" variant="primary" className="float-end" onClick={(e) => {handleAddCMEClicked(e)}}>+ Add New CME</Button>
          </span>
          : <></>
        }
      </Card.Body>
    </Card>
  )
}

function EventDateFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <>
    <Form.Label style={{display: "inline", fontSize: "0.9em"}}>Show CMEs completed:
      &nbsp;
      <Form.Select
        id="cme-date-range-select"
        size="sm"
        className="mt-2"
        style={{display: "inline", width: "auto"}}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        >
          <option value="">(all-time)</option>
          <option value="calendarYear">since start of calendar year</option>
          <option value="yearAgo">in past 12 months</option>
          <option value="twoYearsAgo">in past 24 months</option>
        </Form.Select>
      </Form.Label> 
      </>
  );
}

function customEventDateFilter(rows, columnIds, filterValue) {
  return rows.filter((row) => {
    if (filterValue)
    {
      if (!row.original.eventDate || row.original.eventDate === "")
      {
        // Always show if no date (originally were hiding, but confusing)
        return true;
      }

      const components = row.original.eventDate.split("-");
      if (components.length === 3)
      {
        const expirationDate = new Date(components[0], components[1] - 1, components[2]);
        const now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
        if (filterValue === "calendarYear")
        {
          return expirationDate.getFullYear() === now.getFullYear();
        }
        else if (filterValue === "yearAgo")
        {
          const yearAgo = new Date(new Date().setFullYear(now.getFullYear() - 1));
          return expirationDate >= yearAgo;
        }
        else if (filterValue === "twoYearsAgo")
        {
          const twoYearsAgo = new Date(new Date().setFullYear(now.getFullYear() - 2));
          return expirationDate >= twoYearsAgo;
        }
      }
      
      return false;
    }

    return true;
  });
}

function CmeDefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <Form.Control 
      size="sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} CME(s)...`}
      className="mt-2"
    />
  );
}

// TODO move this somewhere reusable
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    rawCMECategories.forEach((rawCategory) => {
      if (rawCategory.value !== "")
      {
        options.add(rawCategory);
      }
    });
    return [...options.values()];
  }, []);

  // Render a multi-select box
  return (
    <Form.Select size="sm" style={{width: 'auto'}}
      value={filterValue === "" ? "" : filterValue || "all"}
      onChange={(e) => {
        if (e.target.value === "all")
        {
          // This will just short circuit and show all - our custom filter function will NOT be called
          setFilter(undefined);
        }
        else
        {
          setFilter(e.target.value);
        }
      }}
      className="mt-2"
    >
      <option value="all">All</option>
      {options.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label}
        </option>
      ))}
      <option value="">(no category)</option>
    </Form.Select>
  );
}

// TODO move somewhere resuable
function customCategoryFilter(rows, columnIds, filterValue) {
  return rows.filter((row) => {
    if (filterValue === "" && 
    (row.original.category == null || row.original.category === ""))
    {
      // Comes from server as null right now if not set, but handle blank string to be safe
      return true;
    }

    return filterValue === row.original.category;
  });
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});


const CME = ({ id, providerId, title, hours, dollarAmount, category, eventDate, documentFilename, refreshCMEList, canEdit, onCancelEdit, startInEditMode=false }) => {
  const [editing, setEditing] = useState(startInEditMode);
  const [isSaving, setIsSaving] = useState(false);
  const [localTitle, setLocalTitle] = useState(title);
  const [localHours, setLocalHours] = useState(hours);
  const [localDollarAmount, setLocalDollarAmount] = useState(dollarAmount)
  const [localCategory, setLocalCategory] = useState(category);
  const [localEventDate, setLocalEventDate] = useState(eventDate);
  
  const [newDocumentName, setNewDocumentName] = useState(null);
  const [newDocumentContentType, setNewDocumentContentType] = useState(null);
  const [newDocumentFile, setNewDocumentFile] = useState(null);

  const notyf = useContext(NotyfContext);

  function handleDocumentClick(e) {
    e.preventDefault();

    if (isSaving || !documentFilename)
    {
      return;
    }

    authenticatedFetch({
      method: "POST",
      requestData: {filename: documentFilename},
      path:  "education/" + id + "/file/signeddownloadurl",
      successHandler: function(result) {
        window.open(result.url);
      },
      errorHandler: function(error) {
        alert("Something went wrong; please try again.")
      }
    });
  }

  function handleDeleteClicked(e) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete: " + title + "?"))
    {
      authenticatedFetch({
        method: "DELETE",
        path: "education/" + id,
        successHandler: function(result) {
          refreshCMEList();
        },
        errorHandler: function(error) {
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
  }

  function handleEditClicked(e) {
    e.preventDefault();
    setEditing(true);
  }

  function cancelEdit(e) {
    e.preventDefault();
    setEditing(false);
    setLocalTitle(title);
    setLocalHours(hours);
    setLocalDollarAmount(dollarAmount);
    setLocalCategory(category);
    setLocalEventDate(eventDate);

    setNewDocumentName(null);
    setNewDocumentContentType(null);
    setNewDocumentFile(null);

    if (onCancelEdit)
    {
      onCancelEdit();
    }
  }

  const uploadDocument = (cmeId) => {
    if (newDocumentFile !== null && newDocumentName !== null && newDocumentContentType !== null)
    {
      authenticatedFetch({
        method: "POST",
        requestData: {filename: newDocumentName, contentType: newDocumentContentType},
        path: "education/" + cmeId + "/file/signeduploadurl",
        successHandler: function(result) {
          // Got a signed S3 url, PUT to it
          var requestMetadata = {
            method: 'PUT',
            headers: {
                'Content-Type': newDocumentContentType
            },
            body: newDocumentFile
          };
          
          fetch(result.url, requestMetadata).then(res => {
            if (res.ok)
            {
              setNewDocumentName(null);
              setNewDocumentContentType(null);
              setNewDocumentFile(null);
        
              setIsSaving(false); 
              refreshCMEList();
              notyf.open({type: "success", message: id ? "Updated!" : "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
            }
            else
            {
              setIsSaving(false);
              setEditing(true);
              notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
            }
          }).catch((error) => {
            setIsSaving(false);
            setEditing(true);
            notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
          });
        },
        errorHandler: function(error) {
          setIsSaving(false);
          setEditing(true);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
    else
    {
      // Make sure all three are nulled out
      setNewDocumentName(null);
      setNewDocumentContentType(null);
      setNewDocumentFile(null);

      setIsSaving(false);
      refreshCMEList();
      notyf.open({type: "success", message: id ? "Updated!" : "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
    }
  }

  function saveEdit(e) {
    e.preventDefault();

    if (localTitle === '')
    {
      alert("Title can't be blank!");
      return;
    }

    setEditing(false);
    setIsSaving(true);

    if (title !== localTitle || hours !== localHours || dollarAmount !== localDollarAmount || category !== localCategory || eventDate !== localEventDate)
    {
      authenticatedFetch({
        method: id ? "PUT" : "POST",
        requestData: {providerId: parseInt(providerId), title: localTitle, category: localCategory, eventDate: localEventDate, hours: parseFloat(localHours), dollarAmount: parseFloat(localDollarAmount)},
        path: id ? "education/" + id  : "education",
        successHandler: function(result) {
          // Do the save first; if it's a new CME, we need the server ID before we can do the document upload
          uploadDocument(result.id);
        },
        errorHandler: function() {
          setIsSaving(false);
          setEditing(true);
          setLocalTitle(title);
          setLocalHours(hours);
          setLocalDollarAmount(dollarAmount);
          setLocalCategory(category);
          setLocalEventDate(eventDate);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
    else if (id)
    {
      uploadDocument(id);
    }
  }

  const handleEventDateChanged = (date, e) => {
    e.preventDefault();
  
    // If they clear it out, date picker sends it back as "", not null
    if (date === "")
    {
      date = null;
    }

    setLocalEventDate(date);
  }

  function handleTitleChanged(e) {
    e.preventDefault();
    setLocalTitle(e.target.value);
  }

  function handleHoursChanged(e) {
    e.preventDefault();
    setLocalHours(e.target.value);
  }

  function handleDollarAmountChanged(e) {
    e.preventDefault();
    setLocalDollarAmount(e.target.value);
  }

  function handleCategoryChanged(e) {
    e.preventDefault();
    setLocalCategory(e.target.value);
  }

  const handleDocumentAdded = (e) => {
    if (e.target.files && e.target.files.length === 1)
    {
      setNewDocumentName(e.target.files[0].name);
      setNewDocumentContentType(e.target.files[0].type);
      setNewDocumentFile(e.target.files[0]);
    }
  }
  
  var selectedCategoryLabel = '';
  if (localCategory !== '')
  {
    for (let i = 0; i < rawCMECategories.length; i++)
    {
      if (localCategory === rawCMECategories[i].value)
      {
        selectedCategoryLabel = rawCMECategories[i].label;
        break;
      }
    }
  }

  let categoryList = rawCMECategories.map((category, i) => {
    return (
      <option key={i} value={category.value}>{category.label}</option>
    )
  }, this);

  var titleDisplay = localTitle
  if (titleDisplay && titleDisplay.length > 100)
  {
    titleDisplay = titleDisplay.substring(0, 98) + "...";
  }

  return (
    <>
    {editing ?
    <td>
      <Form.Control size="sm" style={{width: "220px"}} autoFocus={true} maxLength={512} value={localTitle} onChange={(e) => {handleTitleChanged(e)}} />
    </td>
    :
    <td style={{maxWidth: "250px"}}>
      <OverlayTrigger
      triggers="[hover,focus]"
      placement="bottom"
      overlay={<Tooltip >{localTitle}</Tooltip>}
      >
        <span>{titleDisplay}</span>
      </OverlayTrigger>
    </td>
    }
    <td style={{fontFamily: "monospace"}}>
      {editing?
      <Form.Control size="sm" style={{width: "55px"}} type="number" maxLength={10} value={localHours} onChange={(e) => {handleHoursChanged(e)}} />
      : localHours
      }
    </td>
    <td style={{fontFamily: "monospace"}}>
      {editing?
      <Form.Control size="sm" style={{width: "55px"}} type="number" maxLength={10} value={localDollarAmount} onChange={(e) => {handleDollarAmountChanged(e)}} />
      : localDollarAmount ? currencyFormatter.format(localDollarAmount) : ""
      }
    </td>
    <td>
      {editing?
        <Form.Select size="sm" value={localCategory} onChange={(e) => {handleCategoryChanged(e)}} style={{width: "auto"}} >
          {categoryList}
        </Form.Select>
      :
      <Badge pill bg="secondary">{selectedCategoryLabel}</Badge>
      }
    </td>
    <td><EditableExpires warnWithColors={false} localExpires={localEventDate} localActive={true} editingExpires={editing} handleExpiresChanged={handleEventDateChanged} blankText="" /></td>
    {editing ?
    <td>
      { newDocumentName ? <><FileText style={{'color': '#3AAFA9', 'height': '16px', 'width': '16px'}}/></> :
      <label className="form-control-sm" style={{cursor:"pointer"}}>...
        <Form.Control type="file" style={{display:"none"}} size="sm" onChange={(e) => handleDocumentAdded(e)} />
      </label>
      }
    </td>
    :
    <td style={{cursor: isSaving || !documentFilename ? "" : "pointer"}}  onClick={(e) => handleDocumentClick(e)}>
      { documentFilename && !isSaving && <Download style={{'color': '#3AAFA9', 'height': '16px', 'width': '16px'}}/>}
      { isSaving && <Spinner animation="border" size="sm" />}
    </td>
    }
    <td style={{width: '70px'}}>
      { canEdit?
        <>
        {editing ? 
        <>
          <X style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {cancelEdit(e)}} />
          &nbsp;<Check style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {saveEdit(e)}} className="text-success"/>
        </>
        :
        <>
          <Edit2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleEditClicked(e)}}/>&nbsp;&nbsp;
          <Trash2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleDeleteClicked(e)}}/>
        </>
        }
        </>
        : <>&nbsp;</>
      }
    </td>
  </>
  )
}


const ProviderCMETable = ({providerId, addingNewCme, onCancelAddNewCme, cmeList, isLoaded, error, canEdit, refreshCMEList}) => {
  const data = cmeList;

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        key: "id",
      },
      {
        Header: "Title",
        accessor: "title",
        key: "title",
        Filter: CmeDefaultColumnFilter,
      },
      {
        Header: "Hours",
        accessor: "hours",
        key: "hours",
      },
      {
        Header: "Cost",
        accessor: "dollarAmount",
        key: "dollarAmount",
      },
      {
        Header: "Category",
        accessor: "category",
        key: "category",
        Filter: SelectColumnFilter,
        filter: customCategoryFilter,
      },
      {
        Header: "Date",
        accessor: "eventDate",
        key: "eventDate",
        Filter: EventDateFilter,
        filter: customEventDateFilter,
        sortType: (rowA, rowB, columnId, desc) => {
          if (rowA.values[columnId] !== rowB.values[columnId]) 
          {
            if (!rowA.values[columnId] || rowA.values[columnId] === "")
            {
              return 1;
            }
            if (!rowB.values[columnId] || rowB.values[columnId] === "")
            {
              return -1;
            }
  
            return rowA.values[columnId].localeCompare(rowB.values[columnId]);
          }
  
          return rowB.original.title.localeCompare(rowA.original.title);;
        },
      },
      {
        Header: "File",
        accessor: "documentFilename",
        key: "documentFilename",
      },
      {
        Header: "",
        accessor: "icons",
        key: "icons",
      },
    ],
  []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        hiddenColumns: ['id'],
        sortBy: [
            {
                id: 'eventDate',
                desc: true
            },
            {
              id: 'title',
              desc: false
          }
        ],
        filters: [
          {
              id: 'eventDate',
              value: "twoYearsAgo"
          },
        ], 
      }
    },
    useFilters,
    useSortBy,
  );

  var totalHours = 0.0;
  var totalCost = 0.0;
  rows.map((row) => {
    if (row.values.hours)
      totalHours += row.values.hours;

    if (row.values.dollarAmount)
      totalCost += row.values.dollarAmount;

      return row;
  });

    // Extract the event date filter
    var eventDateFilter = "";
    for (var i=0; i < headerGroups.length; i++)
    {
      for (var j=0; j < headerGroups[i].headers.length; j++)
      {
        var column = headerGroups[i].headers[j];
        if (column.id === "eventDate")
        {
          eventDateFilter = column.render("Filter");
          break;
        }
      }
    }

  return (
    <>
    <Table className="table-sm" striped bordered hover {...getTableProps()}>
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            const { key, ...restHeaderProps } = (column.id === "icons" || column.id === "documentFilename" || column.id === "category" || column.id === "title") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
            return (
              <th {...restHeaderProps} key={key}>
                {column.render("Header")}
                {(column.id !== "icons" && column.id !== "documentFilename" && column.id !== "category" && column.id !== "title") ? (
                  <>
                  {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                      ) : (
                        <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} className="ms-2" />
                  )}
                  </>
                ) : 
                  (
                    (column.id === "category" || column.id === "title") ? <>{column.render("Filter")}</>
                    :
                    <></>
                  )
                }
              </th>
            )
          }
          )}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {
      error ? (<tr><td colSpan={7}>Something went wrong; please reload the page...</td></tr>) : ( 
        !isLoaded ? (<tr><td colSpan={7}>Loading...</td></tr>) : (
          rows.map((row) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <tr {...restRowProps} key={key}>
              <CME providerId={providerId} id={row.values.id} key={row.values.id} title={row.values.title ? row.values.title : ""} hours={row.values.hours ? row.values.hours : ""} dollarAmount={row.values.dollarAmount ? row.values.dollarAmount : ""} category={row.values.category ? row.values.category : ""} eventDate={row.values.eventDate} documentFilename={row.values.documentFilename} refreshCMEList={refreshCMEList} canEdit={canEdit}/>
            </tr>
          );
        })))
      }
      {
      addingNewCme ?
        <tr><CME providerId={providerId} key={0} startInEditMode={true} title={""} hours={""} dollarAmount={""} category={""} onCancelEdit={onCancelAddNewCme} canEdit={canEdit} refreshCMEList={refreshCMEList}/></tr>
      : <></>
      }
      <tr>
        <td><b>Total</b></td>
        <td><b>{Number((totalHours).toFixed(2))}</b></td>
        <td><b>{currencyFormatter.format(totalCost)}</b></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </Table>
  {eventDateFilter}
  </>
  )
}

const SpecialtySelect = ({ handleSpecialtiesChanged, selectedSpecialties, forceRefresh}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
      authenticatedFetch({
          path: "specialty",
              successHandler: function(result) {
              setIsLoaded(true);
              setData(result);
          },
          errorHandler: function(error) {
              setIsLoaded(true);
              setError(error);
          }
      });
  }, [])

  const options = React.useMemo(() => {
      const options = new Set();
      if(isLoaded && !error && data)
      {
        data.forEach((specialty) => {
          options.add({value: specialty.id, label: specialty.code + ": " + specialty.name});
        });
        return [...options.values()];
      }
      return []
  }, [data]);

  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
      const customStyles = {
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0 5px 0 0",
        }),
        clearIndicator: (styles) => ({
            ...styles,
            padding: 0,
        })
      }

      return (
          <div style={{marginRight: "22px"}}>
              <Select
              options={options}
              styles={customStyles}
              onChange={(values) => {
                handleSpecialtiesChanged(values);
              }}
              value={selectedSpecialties}
              placeholder="Select specialties..."
              isMulti
              theme={(theme) => ({
                ...theme,
                colors: {
                ...theme.colors,
                  primary50: '#DEF2F1',
                  primary25: '#DEF2F1',
                  primary: '#3AAFA9',
                },
              })}
              />
          </div>
      )
  }
}

const CarrierEnrollmentForm = ({formId, formName, format}) => {
  const[isExporting, setIsExporting] = useState(false);

  let { id } = useParams();

  const notyf = useContext(NotyfContext);

  const handleExportClicked = (e) => {
    e.preventDefault();
    setIsExporting(true);

    authenticatedFetch({
      method: "GET",
      path:  "provider/carrierenrollmentform/" + id + "/" + formId,
      successHandler: function(result) {
        window.open(result.url);
        setIsExporting(false);
      },
      errorHandler: function(error) {
        setIsExporting(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  if (isExporting)
  {
    return (
      <tr>
        <td><b>{formName}</b></td>
        <td>
          <Spinner animation="border" size="sm" variant="primary"/>
        </td>
      </tr>
    )
  }
  
  return (
    <tr style={{cursor: "pointer"}} onClick={(e) => {handleExportClicked(e)}}>
      <td><b>{formName}</b></td>
      <td>{format === "CSV" ? <FontAwesomeIcon icon={faFileCsv} size="lg" style={{color: '#2B7A78'}}/>: <FontAwesomeIcon icon={faFilePdf} size="lg" style={{color: '#2B7A78'}}/>}</td>
    </tr>
  )
}

const CarrierEnrollmentForms = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  let { id } = useParams();

  useEffect(() => {
      authenticatedFetch({
          path: "provider/carrierenrollmentforms/" + id,
              successHandler: function(result) {
              setIsLoaded(true);
              setData(result);
          },
          errorHandler: function(error) {
              setIsLoaded(true);
              setError(error);
          }
      });
  }, [])

  var forms;
  if (!isLoaded)
  {
    forms = <tr><td colSpan={2}>Loading...</td></tr>;
  }
  else if (error || !data)
  {
    forms = <tr><td colSpan={2}>Error... Please reload the page...</td></tr>;
  }
  else
  {
    if (!data || data.length === 0)
    {
      forms = <tr><td colSpan={2}>No forms configured...</td></tr>;
    }
    else
    {
      forms = data.map((form, i) => {
        return (
          <CarrierEnrollmentForm key={form.id} formId={form.id} formName={form.name} format={form.format} />
        )
      }, this);
    }
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-25px'}}>
        <Card.Title style={{marginBottom: '-20px'}}>Form Generation
        </Card.Title>
        <div style={{clear: "both"}}>&nbsp;</div>
      </Card.Header>
      <hr />
      <Card.Body>
        <Table className="table-sm" striped hover>
          <tbody>
            {forms}
          </tbody>
        </Table>
    </Card.Body>
  </Card>
  );
  
}

const PracticeRow = ({practices, practice, i}) => {

  const { isH3Admin, authorities } = useContext(UserSettingsContext);

  var startDateDisplay = "";
  if (practice.startDate != null)
  {
    var parts = practice.startDate.split("-");
    const startDateObj = new Date(parts[0], parts[1] - 1, parts[2]);  // Avoid the UTC->local timezone translation issue with js
    startDateDisplay = new Date(startDateObj).toLocaleDateString("en-US");
  }

  var endDateDisplay = "";
  if (practice.endDate != null)
  {
    var parts = practice.endDate.split("-");
    const endDateObj = new Date(parts[0], parts[1] - 1, parts[2]);
    endDateDisplay = new Date(endDateObj).toLocaleDateString("en-US");
  }

  var borderBottomWidth = (i === practices.length - 1 ? "1px" : "0");

  return (
    <tr>
      <td style={{borderBottomWidth: borderBottomWidth, width: "180px"}}>{(isH3Admin || authorities.includes("PRACTICE_BASIC_EDIT") || authorities.includes("PRACTICE_BASIC_VIEW")) ?<a href={"/practice/" + practice.id}>{practice.name}</a>:<>{practice.name}</>}</td>
      <td style={{borderBottomWidth: borderBottomWidth, width: "30px"}}>{practice.isPrimary ? <UserCheck style={{width: "16px", height: "16px", color: "#2B7A78"}}/> : <>&nbsp;</> }</td>
      <td style={{borderBottomWidth: borderBottomWidth}}><b>Start&nbsp;Date:&nbsp;{startDateDisplay}</b></td>
      <td style={{borderBottomWidth: borderBottomWidth}}>{endDateDisplay ? (<b>End&nbsp;Date:&nbsp;{endDateDisplay}</b>) : (<>End&nbsp;Date:&nbsp;(n/a)</>)}</td>
    </tr>
  );
}

const Provider = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingDetails, setEditingDetails] = useState(false);  // TODO these should really be separate components
  const [editingDemographics, setEditingDemographics] = useState(false);
  const [localData, setLocalData] = useState([])
  const [isExpanded, setIsExpanded] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [newProfilePhotoName, setNewProfilePhotoName] = useState(null);
  const [newProfilePhotoContentType, setNewProfilePhotoContentType] = useState(null);
  const [newProfilePhotoFile, setNewProfilePhotoFile] = useState(null);

  const [forceSpecialtyRefresh, setForceSpecialtyRefresh] = useState(0);

  const [isExportingDemographicDetails, setIsExportingDemographicDetails] = useState(false);
  const [isExportingProfile, setIsExportingProfile] = useState(false);
  const [isExportingCv, setIsExportingCv] = useState(false);
  const [isExportingLocations, setIsExportingLocations] = useState(false);

  const [isProviderAccordionExpanded, setIsProviderAccordionExpanded] = useState(false);

  let { id } = useParams();

  const notyf = useContext(NotyfContext);
  const { isH3Admin, authorities, navbarRefresh, setNavbarRefresh } = useContext(UserSettingsContext);

  useEffect(() => {
    authenticatedFetch({
      path: "provider/" + id,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
        // Otherwise seem to point to the same obj, and updating one updates the other?
        var copy={};
        Object.assign(copy, result);
        setLocalData(copy);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [id])

  const handleEditClicked = () => {
    // Treat it like a cancel of the other one if it was open, for simplicity
    setEditingDetails(false);
    setEditingDemographics(false);  
    var copy={};
    Object.assign(copy, data);
    setLocalData(copy);

    setEditing(true);
  }

  const handleEditDetailsClicked = () => {
    setEditing(false);
    setEditingDemographics(false);
    var copy={};
    Object.assign(copy, data);
    setLocalData(copy);

    setEditingDetails(true);
  }

  const handleEditDemographicsClicked = () => {
    setEditing(false);
    setEditingDetails(false);
    var copy={};
    Object.assign(copy, data);
    setLocalData(copy);

    setEditingDemographics(true);
  }

  const handleExpandClicked = (e) => {
    e.preventDefault();
    setIsExpanded(true);
  }

  const handleCollapseClicked = (e) => {
    e.preventDefault();
    setIsExpanded(false);
  }

  const handleProfilePhotoAdded = (e) => {
    if (e.target.files && e.target.files.length === 1)
    {
      setNewProfilePhotoName(e.target.files[0].name);
      setNewProfilePhotoContentType(e.target.files[0].type);
      setNewProfilePhotoFile(e.target.files[0]);
    }
  }

  const handleExportDemographicDetailsClicked = (e) => {
    e.preventDefault();
    setIsExportingDemographicDetails(true);

    authenticatedFetch({
      method: "GET",
      path:  "report/providerdemographicdetails/" + id,
      successHandler: function(result) {
        window.open(result.url);
        setIsExportingDemographicDetails(false);
      },
      errorHandler: function(error) {
        setIsExportingDemographicDetails(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const handleExportProfileClicked = (e) => {
    e.preventDefault();
    setIsExportingProfile(true);

    authenticatedFetch({
      method: "GET",
      path:  "report/providerprofile/" + id,
      successHandler: function(result) {
        window.open(result.url);
        setIsExportingProfile(false);
      },
      errorHandler: function(error) {
        setIsExportingProfile(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }
  
  const handleExportCvClicked = (e) => {
    e.preventDefault();
    setIsExportingCv(true);

    authenticatedFetch({
      method: "GET",
      path:  "report/providercv/" + id,
      successHandler: function(result) {
        window.open(result.url);
        setIsExportingCv(false);
      },
      errorHandler: function(error) {
        setIsExportingCv(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const handleExportLocationsClicked = (e) => {
    e.preventDefault();
    setIsExportingLocations(true);

    authenticatedFetch({
      method: "GET",
      path:  "report/providerlocations/" + id,
      successHandler: function(result) {
        window.open(result.url);
        setIsExportingLocations(false);
      },
      errorHandler: function(error) {
        setIsExportingLocations(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const updateProviderInfo = (editDetails, editDemographics) => {
    const dataToSend = {}
    Object.assign(dataToSend, localData);
    dataToSend['profilePhotoFilename'] = newProfilePhotoName;

    authenticatedFetch({
      path: "provider/" + id,
      method: "POST",
      requestData: dataToSend,
      successHandler: function(result) {
        setIsSaving(false);
        setNewProfilePhotoName(null);
        setNewProfilePhotoContentType(null);
        setNewProfilePhotoFile(null);
        setData(result);
        var copy={};
        Object.assign(copy, result);
        setLocalData(copy);

        notyf.open({type: "success", message: "Provider information updated!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});

        setNavbarRefresh(navbarRefresh + 1);
      },
      errorHandler: function() {
        setIsSaving(false);
        if (editDetails)
        {
          setEditingDetails(true);
        }
        else if (editDemographics)
        {
          setEditingDemographics(true);
        }
        else
        {
          setEditing(true);
        }
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  // Just shared this function for now, since the data is shared
  const handleSaveClicked = (editDetails, editDemographics) => {
    setEditing(false);
    setEditingDemographics(false);
    setEditingDetails(false);
    setIsSaving(true);

    // Do the photo upload first, so we can then record the new filename
    if (newProfilePhotoFile != null && newProfilePhotoName != null && newProfilePhotoContentType != null)
    {
      authenticatedFetch({
        method: "POST",
        requestData: {filename: newProfilePhotoName, contentType: newProfilePhotoContentType, isPublic: true},
        path: "provider/" + id + "/file/signeduploadurl",
        successHandler: function(result) {
          // Got a signed S3 url, PUT to it
          var requestMetadata = {
            method: 'PUT',
            headers: {
                'Content-Type': newProfilePhotoContentType
            },
            body: newProfilePhotoFile
          };
          
          fetch(result.url, requestMetadata).then(res => {
            if (res.ok)
            { 
              updateProviderInfo(editDetails, editDemographics);
            }
            else
            {
              setIsSaving(false);
              setEditing(true);
              notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
            }
          }).catch((error) => {
            setIsSaving(false);
            setEditing(true);
            notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
          });
        },
        errorHandler: function(error) {
          setIsSaving(false);
          setEditing(true);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
    else
    {
      // Make sure all three are nulled out
      setNewProfilePhotoName(null);
      setNewProfilePhotoContentType(null);
      setNewProfilePhotoFile(null);
      updateProviderInfo(editDetails, editDemographics); 
    }
  }

  const handleCancelClicked = () => {
    setEditing(false);
    setEditingDemographics(false);
    setEditingDetails(false);

    var copy={};
    Object.assign(copy, data);
    setLocalData(copy);

    setNewProfilePhotoName(null);
    setNewProfilePhotoContentType(null);
    setNewProfilePhotoFile(null);
  }

  const handleSpecialtiesChanged = (selectedSpecialties)  => {
    var specialties = [];
    for(var i=0; i<selectedSpecialties.length; i++)
    {
      var index = selectedSpecialties[i].label.indexOf(":");
      var code = selectedSpecialties[i].label.substring(0, index);
      var name = selectedSpecialties[i].label.substring(index+2);

      specialties.push({id: selectedSpecialties[i].value, code: code, name: name});
    }

    localData["specialties"] = specialties
    setLocalData(localData)

    // TODO - doesn't trigger the refresh with the specialty subobject update, so using this hack for now
    setForceSpecialtyRefresh(forceSpecialtyRefresh + 1);
  }

  const onValueChanged = (name, newValue) => {
    if (newValue != null && name != null && (name.indexOf("zip") > -1 || name.indexOf("phone") > -1 || name.indexOf("pager") > -1 || name.indexOf("npi") > -1 || name.indexOf("ssn") > -1 ))
    {
      // Remove any remaining mask characters from the truly numeric one; can't do anything about the phone parens and dash on partially completed phones
      newValue = newValue.replace(/_/g, '');

      if (name.indexOf("zip") > -1 && newValue.length <= 6)
      {
        // Chop the trailling dash
        newValue = newValue.substring(0, newValue.length - 1); // Comes through as 980- for example
      }
    }

    if(name === "dob" && newValue === "")
    {
      newValue = null;
    }

    localData[name] = newValue
    setLocalData(localData)
  }

  const onAccordionSelect = (e) => {
    if (e === "0")
    {
      setIsProviderAccordionExpanded(true);
    }
    else
    {
      setIsProviderAccordionExpanded(false);
    }
  }

  let expandedPractices = <></>;
  let collapsedPractices = <></>;
  const maxExpandedPractices = 3;
  
  if (data.practices)
  {
    const practiceList = [];
    for (let i = 0; i < data.practices.length && i < maxExpandedPractices; i++)
    {
      let practice = data.practices[i];
      practiceList.push(<PracticeRow key={practice.id} practices={data.practices} practice={practice} i={i} />);
    }

    expandedPractices = (
      <Table className="table-sm" style={{marginLeft:'-5px', marginTop: '-15px', fontSize: "0.9em", lineHeight: "1"}}>
        <tbody>
        {practiceList}
        </tbody>
      </Table>
    );

    if (data.practices.length > maxExpandedPractices)
    {
      const collapsedPracticeList = [];
      for (let i = maxExpandedPractices; i < data.practices.length; i++)
      {
        let practice = data.practices[i];
        collapsedPracticeList.push(<PracticeRow key={practice.id} practices={data.practices} practice={practice} i={i} />);
      }

      collapsedPractices = (
        <Accordion flush style={{marginTop: "-15px"}} onSelect={(e) => {onAccordionSelect(e)}}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{isProviderAccordionExpanded ? "Hide " : "Show "}{data.practices.length - maxExpandedPractices} more...</Accordion.Header>
            <Accordion.Body style={{padding: '0'}}>
              <Table className="table-sm" style={{marginTop: '5px', marginLeft:'-5px', fontSize: "0.9em", lineHeight: "1"}}>
                <tbody>
                {collapsedPracticeList}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );
    }
  }

  if (!isLoaded)
  {
    return <div>Loading...</div>
  }
  else if (error || !data)
  {
    return <div>Something went wrong... please reload the page...</div>
  }
  else
  {
    var notesReadOnly = '';
    if (localData.notes && localData.notes.length > 0) {
      notesReadOnly = localData.notes.split("\n").map(function(item, idx) {
        return ( 
            <span key={idx}>
                {item}
                <br />
            </span>
         )
      });
    }

    var priorAddressesReadOnly = '';
    if (localData.priorAddresses && localData.priorAddresses.length > 0) {
      priorAddressesReadOnly = localData.priorAddresses.split("\n").map(function(item, idx) {
        return ( 
            <span key={idx}>
                {item}
                <br />
            </span>
         )
      });
    }

    var priorNamesReadOnly = '';
    if (localData.priorNames && localData.priorNames.length > 0) {
      priorNamesReadOnly = localData.priorNames.split("\n").map(function(item, idx) {
        return ( 
            <span key={idx}>
                {item}
                <br />
            </span>
         )
      });
    }

    var maritalStatusReadOnly = '';
    if (localData.maritalStatus !== '')
    {
      for (let i = 0; i < rawMaritalStatuses.length; i++)
      {
        if (localData.maritalStatus === rawMaritalStatuses[i].value)
        {
          maritalStatusReadOnly = rawMaritalStatuses[i].label;
          break;
        }
      }
    }

    var dobDisplay = "";
    var editableDobValue = ""

    if (localData.dob != null)
    {
      var parts = localData.dob.split("-");
      const dobDate = new Date(parts[0], parts[1] - 1, parts[2]);  // Avoid the UTC->local timezone translation issue with js
      dobDisplay = new Date(dobDate).toLocaleDateString("en-US");

      editableDobValue = dobDate.getFullYear() + "-" + String(dobDate.getMonth() + 1).padStart(2, '0') + "-" + String(dobDate.getDate()).padStart(2, '0');
    }

    let specialtyDisplay = localData && localData.specialties && localData.specialties.length > 0
      && localData.specialties.map((specialty) => {
      return (
          <Badge pill bg="secondary" size="sm" key={specialty.id} style={{marginRight: "1px"}}>{specialty.name}</Badge>
      )
    }, this);

    var selectedSpecialtiesTransformed = [];
    if(localData.specialties)
    {
      for(var i = 0; i < localData.specialties.length; i++)
      {
        selectedSpecialtiesTransformed.push({value: localData.specialties[i].id, label: localData.specialties[i].code + ": " + localData.specialties[i].name});
      }
    }

    let canRunDemographicDetailsReport = (isH3Admin || (
      (authorities.includes("PROVIDER_BASIC_VIEW") || authorities.includes("PROVIDER_BASIC_EDIT")) &&
      (authorities.includes("PROVIDER_SENSITIVE_DETAILS_VIEW") || authorities.includes("PROVIDER_SENSITIVE_DETAILS_EDIT")) &&
      (authorities.includes("PROVIDER_DEMOGRAPHICS_VIEW") || authorities.includes("PROVIDER_DEMOGRAPHICS_EDIT")) &&
      (authorities.includes("PROVIDER_DOCUMENT_VIEW") || authorities.includes("PROVIDER_DOCUMENT_EDIT")) &&
      (authorities.includes("PROVIDER_FACILITY_VIEW") || authorities.includes("PROVIDER_FACILITY_EDIT")) &&
      (authorities.includes("PROVIDER_PEER_REFERENCE_VIEW") || authorities.includes("PROVIDER_PEER_REFERENCE_EDIT")) &&
      (authorities.includes("PROVIDER_TRAINING_VIEW") || authorities.includes("PROVIDER_TRAINING_EDIT")) &&
      (authorities.includes("PROVIDER_WORK_HISTORY_VIEW") || authorities.includes("PROVIDER_WORK_HISTORY_EDIT")) &&
      (authorities.includes("PROVIDER_MALPRACTICE_INSURANCE_VIEW") || authorities.includes("PROVIDER_MALPRACTICE_INSURANCE_EDIT")) &&
      (authorities.includes("PROVIDER_BOARD_CERTIFICATION_VIEW") || authorities.includes("PROVIDER_BOARD_CERTIFICATION_EDIT"))
    ));

    let canRunProfileReport = (isH3Admin || (
      (authorities.includes("PROVIDER_BASIC_VIEW") || authorities.includes("PROVIDER_BASIC_EDIT")) &&
      (authorities.includes("PROVIDER_DEMOGRAPHICS_VIEW") || authorities.includes("PROVIDER_DEMOGRAPHICS_EDIT")) &&
      (authorities.includes("PROVIDER_DOCUMENT_VIEW") || authorities.includes("PROVIDER_DOCUMENT_EDIT")) &&
      (authorities.includes("PROVIDER_FACILITY_VIEW") || authorities.includes("PROVIDER_FACILITY_EDIT")) &&
      (authorities.includes("PROVIDER_PEER_REFERENCE_VIEW") || authorities.includes("PROVIDER_PEER_REFERENCE_EDIT")) &&
      (authorities.includes("PROVIDER_BOARD_CERTIFICATION_VIEW") || authorities.includes("PROVIDER_BOARD_CERTIFICATION_EDIT")) &&
      (authorities.includes("PROVIDER_CME_VIEW") || authorities.includes("PROVIDER_CME_EDIT"))
    ));

    let canRunCvReport= (isH3Admin || (
      (authorities.includes("PROVIDER_BASIC_VIEW") || authorities.includes("PROVIDER_BASIC_EDIT")) &&
      (authorities.includes("PROVIDER_DEMOGRAPHICS_VIEW") || authorities.includes("PROVIDER_DEMOGRAPHICS_EDIT")) &&
      (authorities.includes("PROVIDER_TRAINING_VIEW") || authorities.includes("PROVIDER_TRAINING_EDIT")) &&
      (authorities.includes("PROVIDER_WORK_HISTORY_VIEW") || authorities.includes("PROVIDER_WORK_HISTORY_EDIT"))
    ));

    let canRunLocationReport= (isH3Admin || (
      (authorities.includes("PROVIDER_BASIC_VIEW") || authorities.includes("PROVIDER_BASIC_EDIT")) &&
      (authorities.includes("PROVIDER_FACILITY_VIEW") || authorities.includes("PROVIDER_FACILITY_EDIT"))
    ));


    let canRunCarrierEnrollmentFormExport= (isH3Admin || (
      (authorities.includes("PROVIDER_CARRIER_ENROLLMENT_FORM_EXPORT"))
    ));

    let showLeftCol = (isH3Admin || authorities.includes("PROVIDER_SENSITIVE_DETAILS_VIEW") || authorities.includes("PROVIDER_SENSITIVE_DETAILS_EDIT")
        || authorities.includes("PROVIDER_DEMOGRAPHICS_VIEW") || authorities.includes("PROVIDER_DEMOGRAPHICS_EDIT")
        || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT"))
        || canRunDemographicDetailsReport || canRunProfileReport || canRunCvReport || canRunLocationReport || canRunCarrierEnrollmentFormExport;

    return (
        <React.Fragment>
            <Helmet title={data.formattedName}/>
            <Container fluid className="p-0" style={{marginTop: "-20px"}}>
            <ProviderOnboardingUrl providerId={id} />
            <Row style={{background: '#DEF2F1', padding: '20px'}}>
                <Col lg="1">
                {
                editing && newProfilePhotoName != null ?
                <div style={{fontFamily: "monospace", fontSize: "0.8em", height: "90px"}}>{newProfilePhotoName}</div>
                :
                <>
                  {
                  isSaving && newProfilePhotoName != null ?
                  <div style={{height: "90px", textAlign: "center"}}>
                    <Spinner animation="border" size="sm" />
                  </div>
                  :
                  <img
                      src={data.photoUrl == null || data.photoUrl === '' ? doctor1 : data.photoUrl}
                      width="96"
                      height="96"
                      className="rounded-circle me-2"
                      alt="Avatar"
                  />
                  }
                </>
                }
                {
                editing ? 
                <div style={{width: "100px", textAlign: "center", marginTop: "20px"}}>
                  <Form.Control style={{color: "transparent", background: "#DEF2F1", border: "0"}} accept="image/*" type="file" size="sm" onChange={(e) => handleProfilePhotoAdded(e)} />
                </div>
                : <></>
                }
                </Col>
                <Col lg="5" style={{paddingLeft: '25px'}}>
                  <div style={{marginLeft: "-1px", marginBottom: "3px"}}>
                  {
                  editing?
                  <>
                    <EditableDetail originalValue={localData.firstName} width="30%" name="firstName" placeholder="First..." onValueChanged={onValueChanged}/>&nbsp;
                    <EditableDetail originalValue={localData.middleName} width="30%" name="middleName" placeholder="Middle..." onValueChanged={onValueChanged}/>&nbsp;
                    <EditableDetail originalValue={localData.lastName} width="30%" name="lastName" placeholder="Last..." onValueChanged={onValueChanged}/>
                    <br />
                    
                    <EditableDetail originalValue={localData.credentials} width="30%" name="credentials" placeholder="Credentials..." onValueChanged={onValueChanged}/>&nbsp;
                    <EditableDetail originalValue={localData.nickname} width="30%" name="nickname" placeholder="Nickname..." onValueChanged={onValueChanged}/>
                  </>
                  :
                  <h1 className="h3" style={{marginBottom: '-1px'}}>
                    {localData.firstName ? localData.firstName : ""}{localData.nickname ? " \"" + localData.nickname + "\"" : ""}{localData.middleName ? " " + localData.middleName : ""}{localData.lastName ? " " + localData.lastName : ""}{(localData.credentials && localData.credentials !== '') ? ", " + localData.credentials : ""} 
                  </h1>
                  }
                  {
                    editing? 
                    <SpecialtySelect forceRefresh={forceSpecialtyRefresh} handleSpecialtiesChanged={handleSpecialtiesChanged} selectedSpecialties={selectedSpecialtiesTransformed} />
                    :
                    <>
                    {specialtyDisplay}
                    </>
                  }
                  </div>
                  <Table className="table-sm" style={{marginLeft:'-5px', fontSize: "0.95em", lineHeight: "1.2"}}>
                    <tbody>
                      <tr>
                        <td><b>Cell Phone</b></td>
                        <td>
                        {editing?
                        <EditableDetail originalValue={localData.phone1} mask='(999) 999-9999' name="phone1" onValueChanged={onValueChanged}/>
                        :
                        localData.phone1
                        }
                        </td>
                      </tr>
                      <tr>
                        <td><b>Pager</b></td>
                        <td>
                        {editing?
                        <EditableDetail originalValue={localData.pager} mask='(999) 999-9999' name="pager" onValueChanged={onValueChanged}/>
                        :
                        localData.pager
                        }
                        </td>
                      </tr>
                      <tr>
                        <td><b>Work Email</b></td>
                        <td>
                        {editing?
                        <EditableDetail originalValue={localData.email1} name="email1" onValueChanged={onValueChanged}/>
                        :
                        localData.email1
                        }
                        </td>
                      </tr>
                    <tr>
                      <td colSpan="2" style={{borderBottomWidth: "0"}}><b>Associated with</b></td>
                    </tr>
                    </tbody>
                  </Table>
                  {expandedPractices}
                  {collapsedPractices}
                </Col>
                <Col lg="4">
                  <Card>
                    <Card.Header>
                      <Card.Title>Notes</Card.Title>
                    </Card.Header>
                    <Card.Body style={{marginTop: '-30px'}}>{editing ? <EditableDetail originalValue={localData.notes} name="notes" inputType="textarea" onValueChanged={onValueChanged}/> : notesReadOnly}</Card.Body>
                  </Card>
                </Col>
                <Col lg="1"></Col>
                {(isH3Admin || authorities.includes("PROVIDER_BASIC_EDIT")) ?
                <Col lg="1" style={{marginLeft: '0px'}} className="text-end">
                     {editing ?
                     <>
                      <Button size="sm" variant="primary" className="float-end" onClick={() => {handleSaveClicked(false, false)}}>Save</Button>
                      <br />
                      <Button size="sm" variant="secondary" style={{marginTop: '10px'}} className="float-end" onClick={() => {handleCancelClicked()}}>Cancel</Button>
                    </>
                     :
                     <Button size="sm" variant="primary" className="float-end" onClick={() => {handleEditClicked()}}>Edit</Button>
                    }
                </Col>
                : <></>
                }
                <Row style={{justifyContent:"end", fontSize: "0.8em", fontStyle:"italic"}}>
                  <Col lg="auto">Updated: {getActivityDateTimeDisplay(data.updated)}{data.updatedBy != null ? " by " + data.updatedBy : ""}</Col>
                </Row>
            </Row>
            <hr />
            <Row>
              {showLeftCol ?
              <Col xl="3">
              {(isH3Admin || authorities.includes("PROVIDER_SENSITIVE_DETAILS_VIEW") || authorities.includes("PROVIDER_SENSITIVE_DETAILS_EDIT")) ?
              <Card>
                <Card.Header style={{marginBottom: '-25px'}}>
                  <Card.Title style={{marginBottom: '-20px'}}>Provider Details
                    {(isH3Admin || authorities.includes("PROVIDER_SENSITIVE_DETAILS_EDIT")) ?
                    <>
                    {editingDetails ?
                     <span className="float-end">
                      <Button variant="primary" size="sm" className="float-end" onClick={() => {handleSaveClicked(true, false)}}>Save</Button>
                      <br />
                      <Button variant="secondary" size="sm" className="float-end" style={{marginTop: '10px'}} onClick={() => {handleCancelClicked()}}>Cancel</Button>
                    </span>
                     :
                     <Button style={{display: !isExpanded ? "none" : ""}} size="sm" variant="primary" className="float-end" onClick={() => {handleEditDetailsClicked()}}>Edit</Button>
                    }
                    </> : <></>
                    }
                    <br />
                    { isExpanded ?
                    <small><a href="#" onClick={(e) => {handleCollapseClicked(e)}}>-collapse</a></small>
                    :
                    <small><a href="#" onClick={(e) => {handleExpandClicked(e)}}>+expand</a></small>
                    }
                    <div style={{clear: "both"}}>&nbsp;</div>
                  </Card.Title>
                  <hr />
                </Card.Header>
                <Collapse in={isExpanded}>
                  <Table className="table-borderless table-sm">
                    <tbody>
                      <tr>
                        <td><b>NPI:</b></td>
                        {editingDetails?
                        <td><EditableDetail originalValue={localData.npi} name="npi" mask="9999999999" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace"}}>{localData.npi}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>DOB:</b></td>
                        {editingDetails?
                        <td><EditableDetail originalValue={editableDobValue} name="dob" inputType="date" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace"}}>{dobDisplay}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>SSN:</b></td>
                        {editingDetails?
                        <td><EditableDetail originalValue={localData.ssn} name="ssn" mask="999-99-9999" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace"}}>{localData.ssn}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>License:</b></td>
                        {editingDetails?
                        <td><EditableDetail originalValue={localData.license} name="license" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace"}}>{localData.license}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>CAQH:</b></td>
                        {editingDetails?
                        <td><EditableDetail originalValue={localData.caqh} name="caqh" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace"}}>{localData.caqh}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>DEA:</b></td>
                        {editingDetails?
                        <td><EditableDetail originalValue={localData.dea} name="dea" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace"}}>{localData.dea}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>Medicaid:</b></td>
                        {editingDetails?
                        <td><EditableDetail originalValue={localData.medicaid} name="medicaid" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace"}}>{localData.medicaid}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>PTAN:</b></td>
                        {editingDetails?
                        <td><EditableDetail originalValue={localData.medicare} name="medicare" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace"}}>{localData.medicare}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>Other IDs:</b></td>
                        {editingDetails?
                        <td><EditableDetail originalValue={localData.otherIds} name="otherIds" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace"}}>{localData.otherIds}</td>
                        }
                      </tr>
                    </tbody>
                  </Table>
                </Collapse>
                </Card>
                : <></>
                }

              {(isH3Admin || authorities.includes("PROVIDER_DEMOGRAPHICS_VIEW") || authorities.includes("PROVIDER_DEMOGRAPHICS_EDIT")) ?
              <Card>
                <Card.Header style={{marginBottom: '-25px'}}>
                  <Card.Title style={{marginBottom: '-20px'}}>Demographics
                    {(isH3Admin || authorities.includes("PROVIDER_DEMOGRAPHICS_EDIT")) ?
                    <>
                    {editingDemographics ?
                     <span className="float-end">
                      <Button variant="primary" size="sm" className="float-end" onClick={() => {handleSaveClicked(false, true)}}>Save</Button>
                      <br />
                      <Button variant="secondary" size="sm" className="float-end" style={{marginTop: '10px'}} onClick={() => {handleCancelClicked()}}>Cancel</Button>
                    </span>
                     :
                     <Button size="sm" variant="primary" className="float-end" onClick={() => {handleEditDemographicsClicked()}}>Edit</Button>
                    }
                    </> : <></>
                    }
                    <div style={{clear: "both"}}>&nbsp;</div>
                  </Card.Title>
                  <hr />
                </Card.Header>
                <Card.Body>
                  <Table className="table-borderless table-sm">
                    <tbody>
                      <tr>
                        <td><b>Other Phone</b></td>
                        <td>
                        {editingDemographics?
                        <EditableDetail originalValue={localData.phone2} mask='(999) 999-9999' name="phone2" onValueChanged={onValueChanged}/>
                        :
                        localData.phone2
                        }
                        </td>
                      </tr>
                      <tr>
                        <td><b>Personal Email:</b></td>
                        {editingDemographics?
                        <td><EditableDetail originalValue={localData.email2} name="email2" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace", wordBreak: "break-all"}}>{localData.email2}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>Address:</b></td>
                        <td>
                        {editingDemographics ?
                        <EditableAddress address1={localData.address1} address2={localData.address2} city={localData.city} state={localData.state} zip={localData.zip} onValueChanged={onValueChanged} />
                        : 
                        <ReadOnlyAddress address1={localData.address1} address2={localData.address2} city={localData.city} state={localData.state} zip={localData.zip} />
                        }
                        </td>
                      </tr>
                      <tr>
                        <td><b>Prior Addresses:</b></td>
                        {editingDemographics?
                        <td><EditableDetail maxLength={2048} inputType="textarea" originalValue={localData.priorAddresses} name="priorAddresses" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace", wordBreak: "break-all"}}>{priorAddressesReadOnly}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>Prior Names:</b></td>
                        {editingDemographics?
                        <td><EditableDetail inputType="textarea" originalValue={localData.priorNames} name="priorNames" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace", wordBreak: "break-all"}}>{priorNamesReadOnly}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>Birthplace:</b></td>
                        {editingDemographics?
                        <td><EditableDetail originalValue={localData.birthplace} name="birthplace" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace", wordBreak: "break-all"}}>{localData.birthplace}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>Citizenship:</b></td>
                        {editingDemographics?
                        <td><EditableDetail originalValue={localData.citizenship} name="citizenship" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace", wordBreak: "break-all"}}>{localData.citizenship}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>Ethnicity:</b></td>
                        {editingDemographics?
                        <td><EditableDetail originalValue={localData.ethnicity} name="ethnicity" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace", wordBreak: "break-all"}}>{localData.ethnicity}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>Other Languages Spoken:</b></td>
                        {editingDemographics?
                        <td><EditableDetail originalValue={localData.otherLanguages} name="otherLanguages" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace", wordBreak: "break-all"}}>{localData.otherLanguages}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>Marital Status:</b></td>
                        {editingDemographics?
                        <td><MaritalStatusSelect originalValue={localData.maritalStatus} name="maritalStatus" onValueChanged={onValueChanged} /></td>
                        :
                        <td style={{fontFamily: "monospace", wordBreak: "break-all"}}>{maritalStatusReadOnly}</td>
                        }
                      </tr>
                      <tr>
                        <td><b>Spouse:</b></td>
                        {editingDemographics?
                        <td><EditableDetail originalValue={localData.spouse} name="spouse" onValueChanged={onValueChanged}/></td>
                        :
                        <td style={{fontFamily: "monospace", wordBreak: "break-all"}}>{localData.spouse}</td>
                        }
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
                </Card>
                : <></>
                }
                {(canRunDemographicDetailsReport || canRunProfileReport || canRunCvReport || canRunLocationReport) &&
                <Card>
                  <Card.Header style={{marginBottom: '-25px'}}>
                    <Card.Title style={{marginBottom: '-20px'}}>Exports
                    </Card.Title>
                    <div style={{clear: "both"}}>&nbsp;</div>
                  </Card.Header>
                  <hr />
                  <Card.Body>
                    <Table className="table-sm" striped hover>
                      <tbody>
                      {canRunDemographicDetailsReport &&
                        <>
                          {isExportingDemographicDetails ?
                          <tr>
                            <td><b>Demographic Details</b></td>
                            <td>
                              <Spinner animation="border" size="sm" variant="primary"/>
                            </td>
                          </tr>
                          :
                          <tr style={{cursor: "pointer"}} onClick={(e) => {handleExportDemographicDetailsClicked(e)}}>
                            <td><b>Demographic Details</b></td>
                            <td><FontAwesomeIcon icon={faFilePdf} size="lg" style={{color: '#2B7A78'}}/></td>
                          </tr>
                          }
                        </>
                        }
                        {canRunProfileReport &&
                        <>
                          {isExportingProfile ?
                          <tr>
                            <td><b>Profile Report</b></td>
                            <td>
                              <Spinner animation="border" size="sm" variant="primary"/>
                            </td>
                          </tr>
                          :
                          <tr style={{cursor: "pointer"}} onClick={(e) => {handleExportProfileClicked(e)}}>
                            <td><b>Profile Report</b></td>
                            <td><FontAwesomeIcon icon={faFilePdf} size="lg" style={{color: '#2B7A78'}}/></td>
                          </tr>
                          }
                        </>
                        }
                        {canRunCvReport &&
                        <>
                          {isExportingCv ?
                          <tr>
                            <td><b>Provider CV</b></td>
                            <td>
                              <Spinner animation="border" size="sm" variant="primary"/>
                            </td>
                          </tr>
                          :
                          <tr style={{cursor: "pointer"}} onClick={(e) => {handleExportCvClicked(e)}}>
                            <td><b>Provider CV</b></td>
                            <td><FontAwesomeIcon icon={faFilePdf} size="lg" style={{color: '#2B7A78'}}/></td>
                          </tr>
                          }
                        </>
                        }
                        {canRunLocationReport &&
                        <>
                          {isExportingLocations ?
                          <tr>
                            <td><b>Active Locations</b></td>
                            <td>
                              <Spinner animation="border" size="sm" variant="primary"/>
                            </td>
                          </tr>
                          :
                          <tr style={{cursor: "pointer"}} onClick={(e) => {handleExportLocationsClicked(e)}}>
                            <td><b>Active Locations</b></td>
                            <td><FontAwesomeIcon icon={faFilePdf} size="lg" style={{color: '#2B7A78'}}/></td>
                          </tr>
                          }
                        </>
                        }
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
                }
                {canRunCarrierEnrollmentFormExport &&
                  <CarrierEnrollmentForms />
                }
                {(isH3Admin || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT")) ?
                    <Card>
                      <Card.Header>
                        <Card.Title>Open Tasks</Card.Title>
                      </Card.Header>
                      <OpenTasks entityType="provider" entityId={id} />
                    </Card>
                  : <></>
                }
              </Col>
              : <></>
              }
              <Col xl={showLeftCol ? "9" : "12"}>
                {!data.excludedFromCarrierCredentialing &&
                <CarrierParticipationStatusTable providerId={id} providerName={data.formattedName} practices={data.practices} />
                }
                
                {(isH3Admin || authorities.includes("PROVIDER_DATA_HUB_VIEW") || authorities.includes("PROVIDER_DATA_HUB_EDIT")) ?
                <HubParticipationStatusTable entityType="provider" entityId={id} entityName={data.formattedName} canEdit={isH3Admin || authorities.includes("PROVIDER_DATA_HUB_EDIT")}/>
                : <></>
                }

                {(isH3Admin || authorities.includes("PROVIDER_FACILITY_VIEW") || authorities.includes("PROVIDER_FACILITY_EDIT")) ?
                <FacilityParticipationStatusTable entityType="provider" entityId={id} entityName={data.formattedName} canEdit={isH3Admin || authorities.includes("PROVIDER_FACILITY_EDIT")}/>
                : <></>
                }

                {(isH3Admin || authorities.includes("PROVIDER_DOCUMENT_VIEW") || authorities.includes("PROVIDER_DOCUMENT_EDIT")) ?
                <Card>
                  <Card.Header style={{marginBottom: '-25px'}}>
                    <Card.Title style={{marginBottom: '-15px'}}>Documents</Card.Title>
                    <br />
                  </Card.Header>
                  <Card.Body>
                    <DocumentList entityId={id} entityType="provider" canEdit={isH3Admin || authorities.includes("PROVIDER_DOCUMENT_EDIT")} />
                  </Card.Body>
                </Card>
                : <></>
                }

                {(isH3Admin || authorities.includes("PROVIDER_CME_VIEW") || authorities.includes("PROVIDER_CME_EDIT")) ?
                  <ProviderCME providerFormattedName={data.formattedName} providerId={id} canEdit={isH3Admin || authorities.includes("PROVIDER_CME_EDIT")}/>
                : <></>
                }

                {(isH3Admin || authorities.includes("PROVIDER_PEER_REFERENCE_VIEW") || authorities.includes("PROVIDER_PEER_REFERENCE_EDIT")) ?
                  <PeerReferences providerId={id} canEdit={isH3Admin || authorities.includes("PROVIDER_PEER_REFERENCE_EDIT")}/>
                : <></>
                }

                {(isH3Admin || authorities.includes("PROVIDER_TRAINING_VIEW") || authorities.includes("PROVIDER_TRAINING_EDIT")) ?
                  <Trainings providerId={id} canEdit={isH3Admin || authorities.includes("PROVIDER_TRAINING_EDIT")}/>
                : <></>
                }

                {(isH3Admin || authorities.includes("PROVIDER_WORK_HISTORY_VIEW") || authorities.includes("PROVIDER_WORK_HISTORY_EDIT")) ?
                  <WorkHistories providerId={id} canEdit={isH3Admin || authorities.includes("PROVIDER_WORK_HISTORY_EDIT")}/>
                : <></>
                }
                
                {(isH3Admin || authorities.includes("PROVIDER_STATE_LICENSE_VIEW") || authorities.includes("PROVIDER_STATE_LICENSE_EDIT")) ?
                  <StateLicenses providerId={id} canEdit={isH3Admin || authorities.includes("PROVIDER_STATE_LICENSE_EDIT")}/>
                : <></>
                }

                {(isH3Admin || authorities.includes("PROVIDER_MALPRACTICE_INSURANCE_VIEW") || authorities.includes("PROVIDER_MALPRACTICE_INSURANCE_EDIT")) ?
                  <MalpracticeInsurances providerId={id} canEdit={isH3Admin || authorities.includes("PROVIDER_MALPRACTICE_INSURANCE_EDIT")}/>
                : <></>
                }

                {(isH3Admin || authorities.includes("PROVIDER_BOARD_CERTIFICATION_VIEW") || authorities.includes("PROVIDER_BOARD_CERTIFICATION_EDIT")) ?
                  <BoardCertifications providerId={id} canEdit={isH3Admin || authorities.includes("PROVIDER_BOARD_CERTIFICATION_EDIT")}/>
                : <></>
                }

                {(isH3Admin || authorities.includes("PROVIDER_IMMUNIZATION_VIEW") || authorities.includes("PROVIDER_IMMUNIZATION_EDIT")) ?
                  <Immunizations providerId={id} canEdit={isH3Admin || authorities.includes("PROVIDER_IMMUNIZATION_EDIT")}/>
                : <></>
                }
              </Col>
            </Row>
            </Container>
        </React.Fragment>
    );
  }
};

export default Provider;
