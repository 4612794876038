import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Table, Button, Form, Spinner, Tab, Nav, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, useFilters, useExpanded } from "react-table";
import NotyfContext from "../../contexts/NotyfContext";

import authenticatedFetch from "../../utils/fetch"
import { useSearchParams } from "react-router-dom";

import TableMultiSelectColumnFilter from "../../utils/TableMultiSelectColumnFilter";
import TableMultiSelectColumnFilterNoMemo from "../../utils/TableMultiSelectColumnFilterNoMemo";
import rawTaskCategories from "../../utils/taskCategories";
import DocumentList from "../../utils/DocumentList";
import TaskAdditionalActionsModal from "../../utils/TaskAdditionalActionsModal";
import TaskAdditionalInstructionsModal from "../../utils/TaskAdditionalInstructionsModal";
import getAvailableCarrierSubmissionActionTypeDisplay from "../../utils/autoActionTypes";

import { PlusCircle, MinusCircle } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faMitten,
  faCloud,
  faInfinity,
  faRobot,
  faMoneyCheck,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

import getActivityDateTimeDisplay from "../../utils/datetime"
import EditableExpires from "../../utils/EditableExpires";
import rawTaskStatuses from "../../utils/taskStatuses";
import TaskListRow from "../../utils/TaskListRow";
import { getAssigneeAvatarDisplay } from "../../utils/reactTableUtils";


const rawTaskStatusesWithShortLabels = rawTaskStatuses.map((rawTaskStatus) => {
  return {
    value: rawTaskStatus.value,
    label: rawTaskStatus.shortLabel
  }
});

const RevenueTeamNotes = ({ id, notes, enrollmentNotificationEmails, updated, reload }) => {
  if (notes == null)
  {
    notes = '';
  }

  const [saving, setSaving] = useState(false);
  const [originalNotes, setOriginalNotes] = useState(notes);
  const [localNotes, setLocalNotes] = useState(notes);
  const notyf = useContext(NotyfContext);

  const handleCancelClicked = (e) => {
    e.preventDefault();

    if(window.confirm("Discard changes?"))
    {
      setLocalNotes(originalNotes);
    }
  }


  var enrollmentNotificationEmailsDisplay = "";
  if (enrollmentNotificationEmails != null)
  {
    enrollmentNotificationEmailsDisplay = enrollmentNotificationEmails.join(", ");
  }

  const handleSaveClicked = (e) => {
    e.preventDefault();
    setSaving(true);
    authenticatedFetch({
      method: "PUT",
      requestData: {notes: localNotes},
      path: "revenueteam/" + id,
      successHandler: function(result) {
        setSaving(false);
        setOriginalNotes(result.notes);
        reload();
        notyf.open({type: "success", message: "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setSaving(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const onNotesChanged = (e) => {
    e.preventDefault();
    setLocalNotes(e.target.value);
  }

  return (
  <>
      <td>&nbsp;</td>
      <td colSpan={5}>
        <Card>
            <Card.Body>
              <h5>Notes</h5>
              <br />
              <Form.Control onChange={(e) => {onNotesChanged(e)}} value={localNotes} as="textarea" style={{height: "150px"}}></Form.Control>
              <br />
              {saving ? <Spinner animation="border" size="sm" /> :
              originalNotes !== localNotes ?
              <>
                <Button variant="primary" size="sm" onClick={(e) => handleSaveClicked(e)}>Save</Button>&nbsp;
                <Button variant="secondary" size="sm" onClick={(e) => handleCancelClicked(e)}>Cancel</Button>
              </>
              : <></>
              }
              <h5>Enrollment Notification Emails</h5>
              {enrollmentNotificationEmailsDisplay}
              <br /><br />
              <span style={{fontSize: "0.8em", fontStyle:"italic"}}>Updated: {updated}</span>
            </Card.Body>
          </Card>
      </td>
      <td colSpan={1}>
      </td>
      <td colSpan={7}>
        <Card style={{minHeight: "325px"}}>
          <Card.Header style={{marginBottom: '-25px'}}>
            <Card.Title style={{marginBottom: '-15px'}}>Documents</Card.Title>
            <br />
          </Card.Header>
          <Card.Body>
            <DocumentList entityId={id} entityType="revenueteam" canEdit={true} isH3AdminFile={true}/>
          </Card.Body>
        </Card>
      </td>
      <td>&nbsp;</td>
  </>
  );
};

const ContractRatio = ({ uniqueCount, contractCount }) => {

  var className = "";
  var displayText;

  if (contractCount === null)
  {
    displayText = <>{uniqueCount}/<FontAwesomeIcon icon={faInfinity} /></>;
  }
  else
  {
    displayText = uniqueCount + "/" + contractCount;
  
    var ratio = 0.0;
    if (contractCount === 0 && uniqueCount > 0)
    {
      // Nothing allotted on the contract, so red flag any usage at all
      ratio = 1.1;
    }
    if (contractCount > 0)
    {
      ratio = uniqueCount / contractCount;
    }
    
    if (ratio > 1.0)
    {
      className = "text-danger";
    }
    else if (ratio >= 0.9)
    {
      className = "text-warning";
    }
  }

  return <span className={className}>{displayText}</span>;
}


const RevenueTeam = ({ id, name, city, state, practiceCount, activeProviderCount, openTaskCount, dueSoonTaskCount, defaultTaskAssignee, contractType, contractCarriers, contractProviders, contractFacilities, contractUsers, uniqueNpiCount, uniqueCarrierCount, uniqueFacilityCount, uniqueUserCount, startDate, updated }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const notyf = useContext(NotyfContext);

  const handleRevenueTeamClicked = (e) => {
    e.preventDefault();
    
    authenticatedFetch({
      path: "auth/revenueteam",
      method: "PUT",
      requestData: {revenueTeamId: id},
      successHandler: function() {
        sessionStorage.setItem("revenueTeamId", id);
        if (searchParams.get("destination") && searchParams.get("destination") !== '')
        {
          // Note that this will bounce them back to the dashboard if the destination was from a different rev team
          navigate("/?destination=" + encodeURIComponent(searchParams.get("destination")));
        }
        else
        {
          navigate("/");
        }
      },
      errorHandler: function() {
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  var startDateDisplay = "";
  if (startDate != null)
  {
    var parts = startDate.split("-");
    var dateObj = new Date(parts[0], parts[1] - 1, parts[2]);
    startDateDisplay = dateObj.toLocaleDateString("en-US");
  }

  var iconToUse = faMitten;
  if (contractType && contractType === "SAAS")
  {
    iconToUse = faCloud;
  }
  else if (contractType && contractType === "PAY_AS_YOU_GO")
  {
    iconToUse = faMoneyCheck;
  }

  return (
  <>
      <td>{id}</td>
      <td><FontAwesomeIcon className="ms-1"  icon={iconToUse} /></td>
      <td><a href="#" onClick={(e) => {handleRevenueTeamClicked(e)}}>{name}</a></td>
      <td>{city}</td>
      <td>{state}</td>
      <td>{practiceCount}</td>
      <td>{activeProviderCount}</td>
      <td>{openTaskCount}</td>
      <td className={dueSoonTaskCount > 0 ? "text-warning" : ""}>{dueSoonTaskCount}</td>
      <td>{defaultTaskAssignee}</td>
      <td>{startDateDisplay}</td>
      <td style={{borderLeft: "1px solid #E8EAED"}}><ContractRatio uniqueCount={uniqueNpiCount} contractCount={contractProviders} /></td>
      <td><ContractRatio uniqueCount={uniqueCarrierCount} contractCount={contractCarriers} /></td>
      <td><ContractRatio uniqueCount={uniqueFacilityCount} contractCount={contractFacilities} /></td>
      <td><ContractRatio uniqueCount={uniqueUserCount} contractCount={contractUsers} /></td>
  </>
  );
};

const SortableRevenueTeamTable = ({ isLoaded, error, revenueTeamList, reload }) => {
  const data = revenueTeamList; 

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <MinusCircle className="feather" />
            ) : (
              <PlusCircle className="feather" />
            )}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({

              })}
            >
              {row.isExpanded ? (
                <MinusCircle className="feather" />
              ) : (
                <PlusCircle className="feather" />
              )}
            </span>
          ) : null,
      },
      {
        Header: "ID",
        accessor: "id",
        key: "id",
      },
      {
        Header: "Type",
        accessor: "contractType",
        key: "contractType",
      },
      {
        Header: "Name",
        accessor: "name",
        key: "name",
      },
      {
        Header: "City",
        accessor: "city",
        key: "city",
      },
      {
        Header: "State",
        accessor: "state",
        key: "state",
      },
      {
        Header: "Practices",
        accessor: "practiceCount",
        key: "practiceCount",
      },
      {
        Header: "Active Providers",
        accessor: "activeProviderCount",
        key: "activeProviderCount",
      },
      {
        Header: "Open Tasks",
        accessor: "openTaskCount",
        key: "openTaskCount",
      },
      {
        Header: "Tasks Due Soon",
        accessor: "dueSoonTaskCount",
        key: "dueSoonTaskCount",
      },
      {
        Header: "Auto. Task Assignee",
        accessor: "defaultTaskAssignee",
        key: "defaultTaskAssignee",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        key: "startDate",
      },
      {
        Header: "NPIs",
        accessor: "contractProviders",
        key: "contractProviders",
      },
      {
        Header: "Payors per TIN",
        accessor: "contractCarriers",
        key: "contractCarriers",
      },
      {
        Header: "Locations",
        accessor: "contractFacilities",
        key: "contractFacilities",
      },
      {
        Header: "Users",
        accessor: "contractUsers",
        key: "contractUsers",
      },
      {
        accessor: "uniqueNpiCount",
        key: "uniqueNpiCount",
      },
      {
        accessor: "uniqueCarrierCount",
        key: "uniqueCarrierCount",
      },
      {
        accessor: "uniqueFacilityCount",
        key: "uniqueFacilityCount",
      },
      {
        accessor: "uniqueUserCount",
        key: "uniqueUserCount",
      }
    ],
  []
  )
  
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        autoResetSortBy: false,
        autoResetExpanded: false,
        initialState: {
          hiddenColumns: ['uniqueNpiCount', 'uniqueCarrierCount', 'uniqueFacilityCount', 'uniqueUserCount'],
          sortBy: [
              {
                  id: 'name',
                  desc: false
              }
          ]
        }
      },
      useSortBy,
      useExpanded
    );

  return (
    <Card>
      <Card.Body>
      <Table striped hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                    const { key, style, ...restHeaderProps } = (column.id !== "expander" && column.id !== "updated" && column.id !== "contractProviders" && column.id !== "contractCarriers" && column.id !== "contractFacilities" && column.id !== "contractUsers") ? column.getHeaderProps(column.getSortByToggleProps()) : column.getHeaderProps();
                    return (
                    <th {...restHeaderProps} key={key} style={{...style, whiteSpace: (column.id === "id" || column.key === "contractType" || column.key === "name" || column.key === "city" || column.key === "startDate" || column.key === "contractCarriers") ? "nowrap" : ""}}>
                      {column.render("Header")}
                    {(column.id !== "expander" && column.id !== "updated" && column.id !== "contractProviders" && column.id !== "contractCarriers" && column.id !== "contractFacilities" && column.id !== "contractUsers") ? (
                      <>
                        {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="ms-2" />
                          )}
                      </>
                      ) : 
                      <></> 
                    }
                    </th>
                  );
                }
                )}
              </tr>
            );
            }
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
          error ? (<tr><td colSpan={15}>Something went wrong; please reload the page...</td></tr>) : ( 
            !isLoaded ? (<tr><td colSpan={15}>Loading...</td></tr>) : (
            rows.map((row, i) => {
              prepareRow(row);
              const { key, ...restRowProps } = row.getRowProps();
              if (row.id.indexOf(".") > 0)
              {

                var enrollmentNotificationEmailsDisplay = "";
                if (row.values.enrollmentNotificationEmails != null)
                {
                  enrollmentNotificationEmailsDisplay = row.values.enrollmentNotificationEmails.join(", ");
                }

                return (
                <tr {...restRowProps} key={key}>
                  <RevenueTeamNotes key={row.values.id}  id={row.values.id} notes={row.values.name} enrollmentNotificationEmails={row.values.defaultTaskAssignee} updated={row.values.openTaskCount} reload={reload} />
                </tr>
                );
              }

              return (
                <tr {...restRowProps} key={key}>
                  <td>{row.cells[0].render("Cell")}</td>
                  <RevenueTeam key={row.values.id} id={row.values.id} name={row.values.name} city={row.values.city} state={row.values.state} practiceCount={row.values.practiceCount} activeProviderCount={row.values.activeProviderCount} openTaskCount={row.values.openTaskCount} dueSoonTaskCount={row.values.dueSoonTaskCount} defaultTaskAssignee={row.values.defaultTaskAssignee} contractType={row.values.contractType} contractProviders={row.values.contractProviders} contractCarriers={row.values.contractCarriers} contractFacilities={row.values.contractFacilities} contractUsers={row.values.contractUsers} uniqueNpiCount={row.values.uniqueNpiCount} uniqueCarrierCount={row.values.uniqueCarrierCount} uniqueFacilityCount={row.values.uniqueFacilityCount} uniqueUserCount={row.values.uniqueUserCount} startDate={row.values.startDate} updated={row.values.updated}/>
                </tr>
              );
            })))
          }
        </tbody>
      </Table>
      </Card.Body>
    </Card>
    );
}

const RevenueTeamCell = ({ value, row }) => {
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();

  const handleRevenueTeamClicked = (e, revenueTeamId) => {
    e.stopPropagation();

    if (revenueTeamId === null || revenueTeamId === 0)
    {
      return;
    }

    authenticatedFetch({
      path: "auth/revenueteam",
      method: "PUT",
      requestData: {revenueTeamId: revenueTeamId},
      successHandler: function() {
          sessionStorage.setItem("revenueTeamId", revenueTeamId);
          navigate("/");
          window.scrollTo(0, 0);
      },
      errorHandler: function() {
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
    <a href="#" onClick={(e) => handleRevenueTeamClicked(e, row.original.task.revenueTeamId)}>{value}</a>
  )
};

const PracticeCell = ({ value, row }) => {
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();

  const handleRevenueTeamClicked = (e, revenueTeamId, practiceId) => {
    e.stopPropagation();

    if (revenueTeamId === null || revenueTeamId === 0 || practiceId === null || practiceId === 0)
    {
      return;
    }

    authenticatedFetch({
      path: "auth/revenueteam",
      method: "PUT",
      requestData: {revenueTeamId: revenueTeamId},
      successHandler: function() {
          sessionStorage.setItem("revenueTeamId", revenueTeamId);
          navigate("/practice/" + practiceId);
          window.scrollTo(0, 0);
      },
      errorHandler: function() {
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
    <a href="#" onClick={(e) => handleRevenueTeamClicked(e, row.original.task.revenueTeamId, row.original.task.practiceId)}>{value}</a>
  )
};

const ProviderCell = ({ value, row }) => {
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();

  const handleRevenueTeamClicked = (e, revenueTeamId, providerId) => {
    e.stopPropagation();

    if (revenueTeamId === null || revenueTeamId === 0 || providerId === null || providerId === 0)
    {
      return;
    }

    authenticatedFetch({
      path: "auth/revenueteam",
      method: "PUT",
      requestData: {revenueTeamId: revenueTeamId},
      successHandler: function() {
          sessionStorage.setItem("revenueTeamId", revenueTeamId);
          navigate("/provider/" + providerId);
          window.scrollTo(0, 0);
      },
      errorHandler: function() {
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
    <a href="#" onClick={(e) => handleRevenueTeamClicked(e, row.original.task.revenueTeamId, row.original.task.providerId)}>{value}</a>
  )
};

const AdditionalActionsCell = ({ value, row, reloadTaskLists }) => {

  const [showAdditionalActionsModal, setShowAdditionalActionsModal] = useState(false);
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);

  const handleShowAdditionalActions = (e) => { 
    setShowAdditionalActionsModal(true);
  }

  const handleAdditionalActionsModalClose = () => {
      setShowAdditionalActionsModal(false);
  }

  const handleShowInstructions = (e) => {
    setShowInstructionsModal(true);
  }

  const handleInstructionsModalClose = () => {
      setShowInstructionsModal(false);
  }

  const availableCarrierSubmissionActionTypeDisplay = getAvailableCarrierSubmissionActionTypeDisplay(row.original.task.availableCarrierSubmissionActionType);
  const availableCarrierSubmissionActionTypeErrorsDisplay = row.original.task.availableCarrierSubmissionActionTypeErrors ? row.original.task.availableCarrierSubmissionActionTypeErrors.join("; ") : "";

  const hasAdditionalInstructions = row.original.task.additionalInstructionsEntityName && row.original.task.additionalInstructionsEntityName !== "";

  return (
    <span style={{whiteSpace: "nowrap"}}>
      { hasAdditionalInstructions ?
        <>&nbsp;<Button onClick={(e) => handleShowInstructions(e)} variant="primary" size="sm"><FontAwesomeIcon icon={faInfoCircle} style={{width: "16px", height: "16px", marginBottom: "-2px"}}/></Button></>
        : <></>
      }
      { availableCarrierSubmissionActionTypeDisplay !== "" ?
        <>&nbsp;<Button onClick={(e) => handleShowAdditionalActions(e)} variant="primary" size="sm"><FontAwesomeIcon icon={faRobot} style={{width: "16px", height: "16px"}}/></Button></>
        : 
        (availableCarrierSubmissionActionTypeErrorsDisplay ? 
          <OverlayTrigger
            triggers="[hover,focus]"
            placement="bottom"
            overlay={<Tooltip>{availableCarrierSubmissionActionTypeErrorsDisplay}</Tooltip>}
            >
              <span>&nbsp;<Button variant="warning" size="sm"><FontAwesomeIcon icon={faRobot} style={{width: "16px", height: "16px"}}/></Button></span>
          </OverlayTrigger>
        : <></>
        )
      }
      <TaskAdditionalActionsModal show={showAdditionalActionsModal} onClose={handleAdditionalActionsModalClose} id={row.original.task.id} availableCarrierSubmissionActionType={row.original.task.availableCarrierSubmissionActionType} reloadTaskLists={reloadTaskLists} />
      <TaskAdditionalInstructionsModal show={showInstructionsModal} onClose={handleInstructionsModalClose} id={row.original.task.id} category={row.original.task.category} entityName={row.original.task.additionalInstructionsEntityName} />
    </span> 
  )
};


function StatusSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Remove COMPLETED from rawTaskStatuses
  var rawTaskStatusesFiltered = rawTaskStatuses.filter((rawTaskStatus) => {
    return rawTaskStatus.value !== "COMPLETE"
  });

  return (
    <TableMultiSelectColumnFilter width="185px"  filterValue={filterValue} setFilter={setFilter} rawCategories={rawTaskStatusesWithShortLabels} showNoCategory={false}/>
  );
}

function CategorySelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  return (
    <TableMultiSelectColumnFilter width="175px" filterValue={filterValue} setFilter={setFilter} rawCategories={rawTaskCategories} showNoCategory={false}/>
  );
}

function AssigneeSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    authenticatedFetch({
    path: "task/availableh3assignees",
    successHandler: function(result) {
        const newOptions = [];
        for (var i=0; i<result.length; i++)
        {
            var label = result[i].firstName + ' ' + result[i].lastName;
            if (result[i].isH3Admin)
            {
                label = label + " (H3)";
            }

            newOptions.push({value: result[i].userId, label:label});
        }

        setOptions(newOptions);
    },
    errorHandler: function(error) {
    }
    });
}, [])


  return (
    <TableMultiSelectColumnFilterNoMemo width="165px" filterValue={filterValue} setFilter={setFilter} options={options} />
  );
}


function customStatusFilter(rows, columnIds, filterValues) {
  return rows.filter((row) => {
    for (var i = 0; i < filterValues.length; i++) {
      if (filterValues[i].value === row.original.task.status) {
        return true;
      }
    }
    
    return false;
  });
}

function customCategoryFilter(rows, columnIds, filterValues) {
  return rows.filter((row) => {
    for (var i = 0; i < filterValues.length; i++) {
      if (filterValues[i].value === row.original.task.category.value) {
        return true;
      }
    }
    
    return false;
  });
}

function customAssigneeFilter(rows, columnIds, filterValues) {
  return rows.filter((row) => {
    for (var i = 0; i < filterValues.length; i++) {
      // see if filterValues[i].value is contained in row.origin.task.assignees userId
      if (row.original.task.assignees.some((assignee) => assignee.userId === filterValues[i].value)) {
        return true;
      }

    }
    
    return false;
  });
}

const TaskList = ({taskTab, error, isLoaded, data, reloadTaskLists}) => {
  // TODO
  var taskToOpen = null;
  
  var taskData = []
  if (isLoaded && !error)
  {
    // Match the data structure of the legacy task page so we can use its components
    taskData = data.map((task, i) => {
      return {task: task}
    }, this);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "task.status",
        key: "status",
        Filter: StatusSelectColumnFilter,
        filter: customStatusFilter,
        Cell: ({ value, row }) => {
          var statusLabel = "";
          for (var i = 0; i < rawTaskStatusesWithShortLabels.length; i++) {
            if (rawTaskStatusesWithShortLabels[i].value === value) {
              statusLabel = rawTaskStatusesWithShortLabels[i].label;
              break;
            }
          }
          if (value === "BLOCKED_INTERNALLY" || value === "BLOCKED_INTERNALLY_PROVIDER" || value === "BLOCKED_SUBMITTED")
          {
            return <span className="alert-warning">{statusLabel}</span>
          }
          else if (value === "BLOCKED")
          {
            return <span className="alert-danger">{statusLabel}</span>
          }

          return statusLabel;
        },
        // sortType: (rowA, rowB, columnId, desc) => {
        //   return getStatusRankValueString(rowA.values[columnId], desc).localeCompare(getStatusRankValueString(rowB.values[columnId], desc));
        // },
      },
      {
        Header: "Next Action",
        accessor: "task.due",
        key: "due",
        Cell: ({ value, row }) => (
          <EditableExpires localExpires={value} localActive={true} editingExpires={false} redDays={-1} yellowDays={4} warnWithColors={row.original.task.status !== "COMPLETE"} />
        )
      },
      {
        Header: "Deadline",
        accessor: "task.deadline",
        key: "deadline",
        Cell: ({ value, row }) => (
          <EditableExpires localExpires={value} localActive={true} editingExpires={false} redDays={-1} yellowDays={4} warnWithColors={row.original.task.status !== "COMPLETE"} />
        )
      },
      {
        Header: "Category",
        accessor: "task.category.label",
        key: "category",
        Filter: CategorySelectColumnFilter,
        filter: customCategoryFilter,
        Cell: ({ value, row }) => (
            <Badge pill className="float-start" bg="secondary">
              {value}
            </Badge>
        )
      },
      {
        Header: "Revenue Team",
        accessor: "task.revenueTeamName",
        key: "revenueTeam",
        Cell: ({ value, row }) => (
          <RevenueTeamCell value={value} row={row}/>
        )
      },
      {
        Header: "Practice",
        accessor: "task.practiceName",
        key: "practiceName",
        Cell: ({ value, row }) => (
          <PracticeCell value={value} row={row}/>
        )
      },
      {
        Header: "Provider",
        accessor: "task.providerLastNameFirstName",
        key: "providerName",
        Cell: ({ value, row }) => (
          <ProviderCell value={value} row={row}/>
        )
      },
      {
        Header: "Carrier",
        accessor: "task.carrierName",
        key: "carrierName",
        Cell: ({ value, row }) => {
          return <span>{value}</span>
        }
      },
      {
        Header: "Data Hub",
        accessor: "task.hubName",
        key: "hubName",
        Cell: ({ value, row }) => {
          return <span>{value}</span>
        }
      },
      {
        Header: "Facility",
        accessor: "task.facilityName",
        key: "facilityName",
        Cell: ({ value, row }) => {
          return <span>{value}</span>
        }
      },
      {
        Header: "Other",
        accessor: "task.other",
        key: "other",
        Cell: ({ value, row }) => {
          return <span>{value}</span>
        }
      },
      {
        Header: "Other Actions",
        key: "otherActions",
        Cell: ({ value, row }) => (
          <AdditionalActionsCell value={value} row={row} reloadTaskLists={reloadTaskLists}/>
        )
      },
      {
        Header: "Last Activity Date",
        accessor: item => item.task.activity.length > 0 ? item.task.activity[0].created : null,
        key: "lastActivityDate",
        Cell: ({ value, row }) => {
          return getActivityDateTimeDisplay(value, false, false, true);
        }
      },
      {
        Header: "Assignee(s)",
        accessor: "task.assignees",
        key: "assignees",
        Filter: AssigneeSelectColumnFilter,
        filter: customAssigneeFilter,
        Cell: ({ value, row }) => {
          return getAssigneeAvatarDisplay(value);
        }
      }
    ],
  []
  )
  
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: taskData,
      initialState: { 
        sortBy: [
          {
            id: 'task.due',
            desc: false
          }
        ],
      },
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
  );

  return (
    <Card>
        <Card.Body style={{overflowX: "scroll"}}>
        <Table className="table-sm" striped bordered hover {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restHeaderProps } = ( column.key === "assignees" || column.key === "category" || column.key === "status" || column.key === "category") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <th {...restHeaderProps} key={key}>
                      {column.render("Header")}
                      {(column.key !== "assignees" && column.key !== "category" && column.key !== "status" && column.key !== "otherActions") ? (
                        <>
                        {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                        )}
                        </>
                      ) : <></>
                      }
                      {(column.key === "status" || column.key === "category" || (column.key === "assignees" && taskTab !== "mytasks")) ? <>{column.render("Filter")}</>
                        : <></>
                      }
                    </th>
                  )
                }
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
            error ? (<tr><td colSpan={5}>Something went wrong; please reload the page...</td></tr>) : ( 
              !isLoaded ? (<tr><td colSpan={5}>Loading...</td></tr>) : (
              rows.map((row) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                  <TaskListRow showModal={taskToOpen && taskToOpen === row.original.task.id} key={key} row={row} reloadTaskLists={reloadTaskLists}/>
                );
              })))
            }
            <tr>
              <td colSpan={12}>Total: <b>{rows.length}</b></td>
            </tr>
          </tbody>
        </Table>
        </Card.Body>
    </Card>
  )
}

const RevenueTeamList = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    sessionStorage.removeItem("revenueTeamId");
    reload();
  }, [])

  const reload = () => {
    authenticatedFetch({
      path: "revenueteam",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  var revenueTeamList = [];

  if (isLoaded)
  {
    revenueTeamList = data.map((revenueteam, i) => {
      var updatedStr = getActivityDateTimeDisplay(revenueteam.updated) + (revenueteam.updatedBy != null ? " by " + revenueteam.updatedBy : "");
      return (
        { key: revenueteam.id, id: revenueteam.id, name: revenueteam.name, city: revenueteam.city, state: revenueteam.state, practiceCount:revenueteam.practiceCount, 
          activeProviderCount: revenueteam.activeProviderCount, openTaskCount: revenueteam.openTaskCount, dueSoonTaskCount: revenueteam.dueSoonTaskCount, defaultTaskAssignee: revenueteam.defaultTaskAssignee, 
          contractType: revenueteam.contractType, contractProviders: revenueteam.contractProviders, contractCarriers: revenueteam.contractCarriers, contractFacilities: revenueteam.contractFacilities, contractUsers: revenueteam.contractUsers,
          uniqueNpiCount: revenueteam.uniqueNpiCount, uniqueCarrierCount: revenueteam.uniqueCarrierCount, uniqueFacilityCount: revenueteam.uniqueFacilityCount, uniqueUserCount: revenueteam.uniqueUserCount, startDate:revenueteam.startDate,  updated: updatedStr,
          subRows: [{key: revenueteam.id, id: revenueteam.id, name: revenueteam.notes, openTaskCount: updatedStr, defaultTaskAssignee: revenueteam.enrollmentNotificationEmails}]}
      )
    }, this);
  }
  
  return (
    <SortableRevenueTeamTable revenueTeamList={revenueTeamList} isLoaded={isLoaded} error={error} reload={reload} />
  );

};

const RevenueTeams = () => {
  // Get the task data here so we can get the count
  const [myTasksError, setMyTasksError] = useState(null);
  const [myTasksLoaded, setMyTasksLoaded] = useState(false);
  const [myTasks, setMyTasks] = useState([]);
  const [teamTasksError, setTeamTasksError] = useState(null);
  const [teamTasksLoaded, setTeamTasksLoaded] = useState(false);
  const [teamTasks, setTeamTasks] = useState([]);
  const [title, setTitle] = useState("Admin Portal | Revenue Teams");

  useEffect(() => {
    reloadTaskLists();
  }, [])

  const reloadTaskLists = () => {
    authenticatedFetch({
      path: "task/mytasks",
      successHandler: function(result) {
        setMyTasksLoaded(true);
      setMyTasks(result);
      },
      errorHandler: function(error) {
        setMyTasksLoaded(true);
        setMyTasksError(error);
      }
    });

    authenticatedFetch({
      path: "task/teamtasks",
      successHandler: function(result) {
        setTeamTasksLoaded(true);
        setTeamTasks(result);
      },
      errorHandler: function(error) {
        setTeamTasksLoaded(true);
        setTeamTasksError(error);
      }
    });
  }

  var myTasksTitle = "My Tasks";
  if (myTasksLoaded && !myTasksError && myTasks != null)
  {
    myTasksTitle = "My Tasks (" + myTasks.length + ")";
  }

  var teamTasksTitle = "Team Tasks";
  if (teamTasksLoaded && !teamTasksError && teamTasks != null)
  {
    teamTasksTitle = "Team Tasks (" + teamTasks.length + ")";
  }

  const handleTabSelect = (key) => {
    if (key === "second")
    {
      setTitle("Admin Portal | " + myTasksTitle);
    }
    else if (key === "third")
    {
      setTitle("Admin Portal | " + teamTasksTitle);
    }
    else
    {
      setTitle("Admin Portal | Revenue Teams");
    }
  }

  return (
    <React.Fragment>
        <Helmet title={title}/>
        <Tab.Container fluid className="p-0" defaultActiveKey="first" onSelect={(key) => {handleTabSelect(key)}}>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="first">Revenue Teams</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">{myTasksTitle}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">{teamTasksTitle}</Nav.Link>
            </Nav.Item>
          </Nav>
        <Row>
            <Col lg="12">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <RevenueTeamList />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <TaskList taskTab="mytasks" error={myTasksError} isLoaded={myTasksLoaded} data={myTasks} reloadTaskLists={reloadTaskLists}/>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <TaskList taskTab="teamtasks" error={teamTasksError} isLoaded={teamTasksLoaded} data={teamTasks} reloadTaskLists={reloadTaskLists}/>
                </Tab.Pane>
              </Tab.Content>
            </Col>
        </Row>
        </Tab.Container>
    </React.Fragment>
);
};

export default RevenueTeams;
