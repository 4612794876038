import EditableDetail from "./EditableDetail"
import UsStateSelect from "./UsStateSelect"

const EditableAddress = ({address1, address2, city, state, zip, onValueChanged}) => {
    return (
      <>
      <EditableDetail placeholder="Address 1" originalValue={address1} name="address1" onValueChanged={onValueChanged}/>
      <EditableDetail placeholder="Address 2" originalValue={address2} name="address2" onValueChanged={onValueChanged}/>
      <EditableDetail placeholder="City" originalValue={city} name="city" onValueChanged={onValueChanged}/>
      <UsStateSelect placeholder="State" originalValue={state} name="state" onValueChanged={onValueChanged}/>
      <EditableDetail placeholder="Zip" originalValue={zip} name="zip" mask="99999-9999" onValueChanged={onValueChanged}/>
      </>
    );
}

export default EditableAddress;