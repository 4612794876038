import {
  BookOpen,
  Activity,
  Clock,
  Send,
  Users,
  UserPlus,
  File,
  FileText,
  Printer,
  Calendar,
  Star,
  Clipboard,
  Tablet,
  Book,
  Map,
  Edit,
  Home,
} from "react-feather";


/*
const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
  {
    title: "Tools & Components",
    pages: componentsSection,
  },
  {
    title: "Plugins & Addons",
    pages: pluginsSection,
  },
];
*/

const pages = [
  {
    href: "/",
    icon: BookOpen,
    title: "Dashboard"
  },
  {
    href: "/tasks",
    icon: Clock,
    title: "Tasks",
  },
  // {
  //   href: "#",
  //   icon: ShoppingBag,
  //   title: "Documents"
  // },
  {
    href: "#",
    icon: Activity,
    title: "Practices",
    children: [

    ]
  }
  // {
  //   href: "/support",
  //   icon: Send,
  //   title: "Support"
  // },
];

const fppePages = [
  {
    href: "/fppetasks",
    icon: Clock,
    title: "Tasks",
  }
];

const referralPages = [
  {
    href: "/referrals/inbound-faxes",
    icon: Printer,
    title: "Inbound Faxes",
  },
  {
    href: "/referrals/scheduling",
    icon: Calendar,
    title: "Scheduling",
  },
]

const communicationPages = [
  {
    href: "/messaging",
    icon: Send,
    title: "Messaging"
  },
  {
    href: "/messagingtemplates",
    icon: Edit,
    title: "Templates"
  },
]

const reportPages = [
  {
    href: "/reports/kpis",
    icon: Star,
    title: "KPIs"
  },
  {
    href: "/reports/provider",
    icon: File,
    title: "Provider Reports"
  },
  {
    href: "/reports/practice",
    icon: FileText,
    title: "Practice Reports"
  },
  {
    href: "/reports/task",
    icon: Tablet,
    title: "Task Reports"
  },
  {
    href: "/reports/audit",
    icon: Clipboard,
    title: "Audit Reports"
  },
]


const adminPages = [
  {
    href: "/admin/providers",
    icon: UserPlus,
    title: "Provider Mgmt."
  },
  {
    href: "/admin/practices",
    icon: Home,
    title: "Practice Mgmt."
  },
  {
    href: "/admin/users",
    icon: Users,
    title: "User Mgmt."
  },
  {
    href: "/admin/carriers",
    icon: Book,
    title: "Carrier Mgmt. (H3)"
  },
  {
    href: "/admin/locations",
    icon: Map,
    title: "Location Mgmt. (H3)"
  },
]

const navItems = [
  {
    title: "Referrals",
    pages: referralPages
  },
  {
    title: "Enrollment",
    pages: pages
  },
  {
    title: "FPPE",
    pages: fppePages
  },
  {
    title: "Communication",
    pages: communicationPages
  },
  {
    title: "Reports & Analytics",
    pages: reportPages
  },
  {
    title: "Admin",
    pages: adminPages
  }

];

export default navItems;
